import styled from 'styled-components';
import { Button, ListItem } from '@mui/material';
import { yellow } from '@mui/material/colors';

export const StyledListItem = styled(ListItem)`
  float: left;
  display: inline-block;
  width: auto;
`;

export const StyledMenuButton = styled(Button)`
  font-weight: bold !important;
  justify-content: left !important;
  padding-left: ${(p) => p.theme.spacing(2)} !important;
  text-transform: none !important;
  width: 100%;

  &.activeBtn {
    background: linear-gradient(135deg, ${yellow[700]} 30%, ${yellow[500]} 90%);
    color: ${(p) => p.theme.palette.common.black};
  }
`;

export const StyledCreateButton = styled(Button)`
  background: linear-gradient(135deg, ${yellow[700]} 30%, ${yellow[500]} 90%);
  width: 100%;
`;

export const StyledNav = styled.nav`
  display: inline-block;
  margin: 0 0 ${(p) => p.theme.spacing(2)};
  width: 100%;
`;
