import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { BottomSpace } from './styles';
import { greeting } from 'helpers/greeting';
import ColouredBox from 'components/Box/ColouredBox';
import StaticIntro from 'components/StaticIntro';
import ProjectsListCard from 'components/Cards/ProjectsListCard';
import TicketCreationCard from 'components/Cards/TicketCreationCard';
import SupportTicketsCard from 'components/Cards/SupportTicketsCard';
import * as ROUTES from 'constants/routes';

import SupportClients from 'components/Cards/SupportClients';

const Dashboard = ({
  firstname,
  lastname,
  role,
  openTickets = 0,
  solvedTickets = 0,
  closedTickets = 0,
  projectCount = 0
}) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={2}></Grid>

      <Grid item xs={12} md={8}>
        <BottomSpace>
          <StaticIntro pretitle="Dashboard" title={`${greeting()} ${firstname}`}>
            Here’s what’s happening with your projects today
          </StaticIntro>
        </BottomSpace>

        <BottomSpace>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <ColouredBox
                number={openTickets}
                link={ROUTES.DASHBOARD_SUPPORT}
                title="Open Support Tickets"
                color="red"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ColouredBox
                number={closedTickets + solvedTickets}
                link={ROUTES.DASHBOARD_SUPPORT_CLOSED}
                title="Closed Support Tickets"
                color="green"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <ColouredBox number={projectCount} link={ROUTES.DASHBOARD_PROJECTS} title="Projects" />
            </Grid>
          </Grid>
        </BottomSpace>

        {role === 'superAdmin' && (
          <BottomSpace>
            <SupportClients />
          </BottomSpace>
        )}

        <BottomSpace>
          <ProjectsListCard />
        </BottomSpace>

        <BottomSpace>
          <SupportTicketsCard status="open" border="true" search={false} ticketsToShow={5} />
        </BottomSpace>

        <BottomSpace>
          <TicketCreationCard />
        </BottomSpace>
      </Grid>

      <Grid item xs={12} md={2}></Grid>
    </Grid>
  );
};

const mapStateToProps = ({
  auth: { firstname, lastname, projectCount, role },
  support: { openTickets, closedTickets, solvedTickets }
}) => ({
  firstname,
  lastname,
  role,
  openTickets,
  closedTickets,
  solvedTickets,
  projectCount
});

export default connect(mapStateToProps)(Dashboard);
