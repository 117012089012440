import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import { yellow } from '@mui/material/colors';

export const StyledLoader = styled(Loader)`
  margin-bottom: -2px;
  position: relative;
  top: 2px;

  svg {
    rect {
      fill: ${yellow[600]};
    }
  }
`;
