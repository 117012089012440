import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button, Grid, FormControl } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ForgotLink, IconButtonPosition, ErrorMessage, StyledField } from './styles';
import { doSignInWithEmailAndPassword } from 'actions/auth/signIn';
import LoadingSpinner from 'components/Loaders/LoadingSpinner';
import { validationSchema } from './validation';
import * as ROUTES from 'constants/routes';

const LoginForm = ({ loginError, doSignInWithEmailAndPassword, lightState }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword({ showPassword: !showPassword });
  };

  const submitComplete = ({ username, password }, { setSubmitting }) => {
    setSubmitting(true);
    doSignInWithEmailAndPassword(username, password);
  };

  return (
    <Formik
      initialValues={{
        username: '',
        password: ''
      }}
      validationSchema={validationSchema}
      onSubmit={(values, formikBag) => submitComplete(values, formikBag)}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <StyledField
            component={TextField}
            name="username"
            label="Email"
            autoComplete="email"
            margin="normal"
            required
            fullWidth
            disabled={false}
            color="primary"
          />

          <FormControl fullWidth style={{ marginBottom: '30px' }}>
            <StyledField
              component={TextField}
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="password"
              margin="normal"
              required
              fullWidth
              disabled={false}
            />
            <IconButtonPosition
              color={lightState ? 'primary' : 'secondary'}
              aria-label="Toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButtonPosition>
          </FormControl>

          <ForgotLink to={ROUTES.AUTH_PASSWORD_FORGET}>Forgot Password?</ForgotLink>

          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Button
                type="submit"
                size="large"
                variant="contained"
                color={lightState ? 'primary' : 'secondary'}
                fullWidth
              >
                {isSubmitting && !loginError ? <LoadingSpinner size={20} /> : 'Login'}
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                component={Link}
                size="large"
                variant="outlined"
                color={lightState ? 'primary' : 'secondary'}
                fullWidth
                to={ROUTES.AUTH_SIGN_UP}
              >
                Sign Up
              </Button>
            </Grid>
          </Grid>

          {loginError && <ErrorMessage component="p">{loginError}</ErrorMessage>}
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = ({ errors: { loginError } }) => ({ loginError });

export default connect(mapStateToProps, { doSignInWithEmailAndPassword })(LoginForm);
