import React from 'react';
import { Divider, Typography, Table, TableBody, TableCell, TableRow, Link } from '@mui/material';
// import { LockOpen } from '@mui/icons-material';
import { /*IconButton,*/ StyledPaper, StyledPaperInner } from '../styles';
import SslChip from 'components/SslChip';

const ProjectDetailsTable = ({ data }) => {
  const {
    project: { title, summary, projectType, projectCode, websiteUrl, ssl }
  } = data || {};

  return (
    <StyledPaper>
      <StyledPaperInner>
        <Typography variant="h6" component="h2">
          Project Details
        </Typography>
      </StyledPaperInner>
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell scope="row">Title</TableCell>
            <TableCell>{title}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell scope="row">Summary</TableCell>
            <TableCell>{summary}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell scope="row">Project Type</TableCell>
            <TableCell>{projectType}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell scope="row">SSL</TableCell>
            <TableCell>
              <SslChip ssl={ssl} title={title} />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell scope="row">Project Code</TableCell>
            <TableCell>{projectCode}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell style={{ border: 'none' }} scope="row">
              Website URL
            </TableCell>
            <TableCell style={{ border: 'none' }}>
              <Link href={websiteUrl} rel="noopener noreferrer" target="_blank">
                {websiteUrl}
              </Link>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {/* @todo - build in project update functionality */}
      {/* <StyledPaperInner>
        <IconButton>
          <LockOpen /> Update Project Details
        </IconButton>
      </StyledPaperInner> */}
    </StyledPaper>
  );
};

export default ProjectDetailsTable;
