import React from 'react';
import { Query } from '@apollo/client/react/components';
import { Typography } from '@mui/material';
import { Footer } from './styles';
import { SITE_FOOTER_QUERY } from './data';

const SiteFooter = () => (
  <Query query={SITE_FOOTER_QUERY}>
    {({ loading, error, data }) => {
      if (loading || error) return null;

      const {
        content: {
          site: { footer }
        }
      } = data || {};

      if (footer === null) return null;

      return (
        <Footer>
          {footer &&
            footer.split('\n').map((i, key) => (
              <Typography component="p" color="textSecondary" key={key}>
                {i}
              </Typography>
            ))}
        </Footer>
      );
    }}
  </Query>
);

export default SiteFooter;
