import React from 'react';
import styled, { css } from 'styled-components';
import { yellow } from '@mui/material/colors';

export const StyledRadioButton = styled(({ type, active, ...other }) => <div {...other} />)`
  background: ${(p) => (p.type === 'light' ? '#e7e7e7' : '#000000')};
  border-radius: 8px;
  cursor: pointer;
  padding: 16px 12px 8px;
  position: relative;
  margin-bottom: 24px;
  transition: ${(p) =>
    p.theme.transitions.create(['opacity'], {
      easing: p.theme.transitions.easing.sharp,
      duration: p.theme.transitions.duration.leavingScreen
    })} !important;

  &:hover {
    opacity: 0.8;
  }

  ${(p) =>
    p.active &&
    css`
      &:after {
        content: '';
        background: ${yellow[800]};
        border-radius: 100%;
        overflow: hidden;
        width: 8px;
        height: 8px;
        position: absolute;
        top: calc(100% + 10px);
        left: 50%;
        transform: translate3d(-50%, 0, 0);
      }
    `}

  .box {
    background-color: rgb(145, 158, 171);
    border-radius: 6px;
    margin-bottom: 6px;

    &-one {
      height: 40px;
      opacity: 0.64;
    }

    &-two {
      height: 28px;
      opacity: 0.32;
    }

    &-three {
      height: 16px;
      opacity: 0.16;
    }

    &-four {
      margin: 20px auto 6px;
      height: 4px;
      width: 32%;
      border-radius: 8px;
      opacity: 0.32;
    }
  }

  ${(p) =>
    p.type === 'dark' &&
    css`
      .box {
        opacity: 1;

        &-one {
          background-color: ${yellow[700]};
        }

        &-two,
        &-four {
          background-color: ${yellow[400]};
        }

        &-three {
          background-color: ${yellow[200]};
        }
      }
    `}
`;
