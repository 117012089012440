import React from 'react';
import { connect } from 'react-redux';
import { Divider, Grid, Table, TableBody, TableRow, TableCell, Typography, Tab } from '@mui/material';
import { Switch, Route, Link, BrowserRouter } from 'react-router-dom';
import { StyledTabs, StyledPaper, StyledPaperInner, StyledChip } from './styles';
import StaticIntro from 'components/StaticIntro';
import UpdateUserDetailsForm from 'components/Forms/UpdateUserDetailsForm';
import * as ROUTES from 'constants/routes';

const Settings = ({ auth, darkState }) => {
  return (
    <BrowserRouter>
      <Grid container spacing={4}>
        <Grid item xs={12} md={2}></Grid>

        <Grid item xs={12} md={8}>
          <StaticIntro pretitle="Account" title="Update Your Profile">
            Update account details and relevant forms will be here
          </StaticIntro>

          <Route
            path="/"
            render={({ location }) => (
              <>
                <StyledTabs indicatorColor="primary" value={location.pathname}>
                  <Tab
                    label="General"
                    component={Link}
                    to={ROUTES.DASHBOARD_ACCOUNT}
                    value={ROUTES.DASHBOARD_ACCOUNT}
                  />
                  <Tab
                    label="Security"
                    component={Link}
                    to={ROUTES.DASHBOARD_ACCOUNT_SECURITY}
                    value={ROUTES.DASHBOARD_ACCOUNT_SECURITY}
                  />
                  <Tab
                    label="Notifications"
                    component={Link}
                    to={ROUTES.DASHBOARD_ACCOUNT_NOTIFICATIONS}
                    value={ROUTES.DASHBOARD_ACCOUNT_NOTIFICATIONS}
                  />
                  <Tab
                    label="Billing"
                    component={Link}
                    to={ROUTES.DASHBOARD_ACCOUNT_BILLING}
                    value={ROUTES.DASHBOARD_ACCOUNT_BILLING}
                  />
                </StyledTabs>

                <Switch>
                  <Route
                    path={ROUTES.DASHBOARD_ACCOUNT_SECURITY}
                    render={() => (
                      <StyledPaper>
                        <StyledPaperInner>
                          <Typography variant="h6" component="h2">
                            Update your password
                          </Typography>
                        </StyledPaperInner>
                        <Divider />
                        <StyledPaperInner>Form coming soon...</StyledPaperInner>
                      </StyledPaper>
                    )}
                  />

                  <Route
                    path={ROUTES.DASHBOARD_ACCOUNT_NOTIFICATIONS}
                    render={() => (
                      <StyledPaper>
                        <StyledPaperInner>
                          <Typography variant="h6" component="h2">
                            Notifications
                          </Typography>
                        </StyledPaperInner>
                        <Divider />
                        <StyledPaperInner>Coming soon...</StyledPaperInner>
                      </StyledPaper>
                    )}
                  />

                  <Route
                    path={ROUTES.DASHBOARD_ACCOUNT_BILLING}
                    render={() => (
                      <StyledPaper>
                        <StyledPaperInner>
                          <Typography variant="h6" component="h2">
                            Billing
                          </Typography>
                        </StyledPaperInner>
                        <Divider />
                        <StyledPaperInner>Coming soon...</StyledPaperInner>
                      </StyledPaper>
                    )}
                  />

                  <Route
                    path={ROUTES.DASHBOARD_ACCOUNT}
                    render={() => (
                      <StyledPaper>
                        <StyledPaperInner>
                          <Typography variant="h6" component="h2">
                            Your Details
                          </Typography>
                        </StyledPaperInner>
                        <Divider />
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell scope="row">Full Name</TableCell>
                              <TableCell>
                                {auth.firstname} {auth.lastname}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell scope="row">Email</TableCell>
                              <TableCell>{auth.email}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell scope="row">Verified</TableCell>
                              <TableCell>
                                <StyledChip
                                  label={auth.verified ? 'Verified' : 'Not Verified'}
                                  size="small"
                                  variant="outlined"
                                  verified={auth.verified.toString()}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>

                        <StyledPaperInner>
                          <UpdateUserDetailsForm user={auth} />
                        </StyledPaperInner>
                      </StyledPaper>
                    )}
                  />
                </Switch>
              </>
            )}
          />
        </Grid>

        <Grid item xs={12} md={2}></Grid>
      </Grid>
    </BrowserRouter>
  );
};

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps)(Settings);
