import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Chip, Grid, Paper } from '@mui/material';
import { red, green } from '@mui/material/colors';

export const StyledContainer = styled(Grid)`
  padding-bottom: ${(p) => p.theme.spacing(8)} !important;
  padding-top: ${(p) => p.theme.spacing(8)} !important;
`;

export const StyledChip = styled(Chip)`
  border-color: ${(props) => (props.open === 'true' ? red[700] : green[700])} !important;
  color: ${(props) => (props.open === 'true' ? red[700] : green[700])} !important;
`;

export const StyledLink = styled(Link)`
  color: ${(p) => p.theme.palette.primary.main} !important;
`;

export const StyledPaper = styled(Paper)`
  border: ${(props) => (props.border === 'false' ? 'none' : '1px solid inherit')} !important;
`;

export const StyledPaperInner = styled.div`
  padding: ${(p) => p.theme.spacing(2)};
`;
