import axios from 'axios';
import { print } from 'graphql';
import { authRef } from 'config/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { history } from 'helpers/History';
import { GET_USER_QUERY } from './data';
import { LOGIN_SUCCESS, LOGIN_ERROR } from 'actions/types';
import { friendlyFirebaseMessage } from 'helpers/firebaseMessages';
import * as ROUTES from 'constants/routes';

export const doSignInWithEmailAndPassword = (email, password) => (dispatch) => {
  dispatch({
    type: LOGIN_ERROR,
    data: {
      loginError: false
    }
  });

  signInWithEmailAndPassword(authRef, email, password)
    .then(async (result) => {
      /** get the user token */
      await authRef.currentUser
        .getIdToken(true)
        .then(async (idToken) => {
          /** take the token and  */
          await axios
            .post(
              process.env.REACT_APP_BACKEND_URL,
              {
                query: print(GET_USER_QUERY)
              },
              {
                headers: {
                  authorization: idToken
                }
              }
            )
            .then(({ data: { data } }) => {
              const { user } = data;

              /** On successful login fetch the user role */
              dispatch({
                type: LOGIN_SUCCESS,
                data: {
                  firstname: user.firstname,
                  lastname: user.lastname,
                  email: user.email,
                  verified: user.verified,
                  role: user.role,
                  projectCount: user.projectCount
                }
              });
              history.push(ROUTES.DASHBOARD);
            });
        })
        .catch(({ code }) => {
          dispatch({
            type: LOGIN_ERROR,
            data: {
              loginError: friendlyFirebaseMessage(code)
            }
          });
        });
    })
    .catch(({ code }) => {
      dispatch({
        type: LOGIN_ERROR,
        data: {
          loginError: friendlyFirebaseMessage(code)
        }
      });
    });
};
