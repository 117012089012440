import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { LoaderWrapper, SongText } from './styles';

const PageLoader = ({ hideText }) => {
  const [number, setNumber] = useState(0);
  const [loadingText, setLoadingText] = useState([
    ('Dearly beloved, we have gathered here today to get through this thing called life.',
    'Is this the real life? Is this just fantasy?',
    "Somebody once told me the world is gonna roll me, I ain't the sharpest tool in the shed.",
    "Go shawty, it's your birthday, we gon' party like it's yo birthday.",
    'Now this is a story all about how my life got flipped turned upside down.',
    "Coming out of my cage and I've been doing just fine, gotta gotta be down because I want it all.",
    "Making my way downtown, walking fast, faces pass and I'm homebound.",
    'Just a small town girl, living in a lonely world.',
    'What you want, baby, I got it!',
    'Get your motor running...get out on the highway.',
    'Bass, how low can you go?',
    'Birds flying high you know how I feel. Sun in the sky you know how I feel...',
    'And now, the end is here / And so I face the final curtain...',
    'This is Major Tom to Ground Control',
    "Oh no no no I'm a rocket man")
  ]);

  useEffect(() => {
    const min = 0;
    const max = loadingText.length - 1;
    let randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

    setNumber(randomNumber);

    const interval = setInterval(() => {
      randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;

      setNumber(randomNumber);
    }, 6000);

    return () => clearInterval(interval);
  }, [loadingText, setLoadingText]);

  return (
    <LoaderWrapper>
      <Loader type="Audio" height={60} width={60} />
      {!hideText && <SongText>{loadingText[number]}</SongText>}
    </LoaderWrapper>
  );
};

export default PageLoader;
