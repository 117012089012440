import { useState } from 'react';
import MUIRichTextEditor from 'mui-rte';
import { withRouter } from 'react-router-dom';
import { uniqueId } from 'lodash';
import { history } from 'helpers/History';
import { stateToHTML } from 'draft-js-export-html';
import { connect } from 'react-redux';
import { Query, Mutation } from '@apollo/client/react/components';
import { Form, Field, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { MenuItem, Typography } from '@mui/material';
import { SubmitButton } from './styles';
import { PROJECTS_QUERY, CREATE_SUPPORT_TICKET_MUTATION } from './data';
import { SUPPORT_TICKETS_QUERY } from 'components/Cards/SupportTicketsCard/data';
import { validationSchema } from './validation';
import DropZone from 'components/Attachments/DropZone';
import FormHeader from 'components/FormHeader';
import * as ROUTES from 'constants/routes';

const SupportTicketForm = ({ verified, single, location, align, lightMode }) => {
  /** Comment textarea */
  const [initialComment, setInitialComment] = useState({
    key: uniqueId(),
    value: ''
  });

  /** Dropzone Attachments */
  const [attachments, setAttachments] = useState([]);
  const removeAllAttachments = () => {
    setAttachments([]);
  };

  const disabled = verified ? false : true;
  const priorities = ['Highest', 'High', 'Medium', 'Low', 'Lowest'];

  return (
    <>
      <FormHeader
        title="Create a support ticket"
        subtitle="Raise a support ticket and we will get back to you shortly"
        align={align}
        lightState={true}
      />

      {disabled && (
        <Typography component="p">You must verify your email address to be able to submit a support ticket.</Typography>
      )}

      <Mutation mutation={CREATE_SUPPORT_TICKET_MUTATION}>
        {(createSupportTicket, { loading, error, data }) => (
          <Formik
            initialValues={{
              subject: '',
              project: single ? single : '',
              priority: 'Medium',
              files: [],
              comment: ''
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
              const { subject, project, priority, files, comment } = values;

              try {
                const submitted = await createSupportTicket({
                  variables: {
                    subject: `${project}: ${subject}`,
                    priority,
                    comment,
                    files
                  },
                  refetchQueries: [{ query: SUPPORT_TICKETS_QUERY }]
                });

                if (submitted) {
                  resetForm();

                  /** Reset the comment textarea */
                  setInitialComment({
                    key: uniqueId(),
                    value: ''
                  });

                  /** Reset the attachments */
                  removeAllAttachments();

                  /** Redirect to the support ticket */
                  if (submitted.data.createSupportTicket) {
                    history.push(`${ROUTES.DASHBOARD_SUPPORT}/ticket/${submitted.data.createSupportTicket.key}`);
                  }
                }
              } catch (error) {
                console.error('Error creating support ticket: ', error);
                // Handle the error appropriately in your application
              }
            }}
          >
            {({ handleSubmit, setFieldValue, isSubmitting }) => (
              <>
                <Form noValidate onSubmit={handleSubmit}>
                  <Field
                    component={TextField}
                    variant="outlined"
                    name="subject"
                    label="Subject"
                    margin="normal"
                    required
                    fullWidth
                    disabled={disabled}
                  />
                  {single ? (
                    <Field
                      component={TextField}
                      type="hidden"
                      style={{ display: 'none' }}
                      variant="outlined"
                      name="project"
                      disabled={disabled}
                    />
                  ) : (
                    <Query query={PROJECTS_QUERY}>
                      {({ loading, error, data }) => {
                        if (loading) {
                          return (
                            <Field
                              component={TextField}
                              variant="outlined"
                              name="project"
                              label="Loading projects..."
                              select
                              margin="normal"
                              fullWidth
                              disabled={true}
                            >
                              <MenuItem value="">Loading projects...</MenuItem>
                            </Field>
                          );
                        }

                        if (error) return null;

                        const { projects } = data || {};

                        if (typeof projects === 'undefined') {
                          return null;
                        }

                        return (
                          <Field
                            component={TextField}
                            variant="outlined"
                            name="project"
                            label="Select Project"
                            select
                            margin="normal"
                            required
                            fullWidth
                            disabled={disabled}
                          >
                            <MenuItem value="">Select project</MenuItem>
                            {projects &&
                              projects.map(({ title, projectCode }, index) => (
                                <MenuItem key={index} value={projectCode}>
                                  {title}
                                </MenuItem>
                              ))}
                          </Field>
                        );
                      }}
                    </Query>
                  )}

                  <Field
                    component={TextField}
                    variant="outlined"
                    name="priority"
                    label="Urgency"
                    select
                    margin="normal"
                    required
                    fullWidth
                    disabled={disabled}
                  >
                    {priorities.map((priority, index) => (
                      <MenuItem key={index} value={priority}>
                        {priority}
                      </MenuItem>
                    ))}
                  </Field>

                  <MUIRichTextEditor
                    id="support-form"
                    label="Your message"
                    key={initialComment.key}
                    value={initialComment.value}
                    onChange={(state) => setFieldValue('comment', stateToHTML(state.getCurrentContent()))}
                    toolbar={true}
                    toolbarButtonSize="small"
                    controls={[
                      'bold',
                      'italic',
                      'underline',
                      'strikethrough',
                      'undo',
                      'redo',
                      'link',
                      'numberList',
                      'bulletList',
                      'quote'
                    ]}
                  />

                  <DropZone setFieldValue={setFieldValue} attachments={attachments} setAttachments={setAttachments} />

                  <SubmitButton
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                    disabled={disabled || isSubmitting}
                  >
                    Submit ticket
                  </SubmitButton>
                </Form>

                {loading && <div>Loading...</div>}
                {error && <div>There's appears to be an error. Please try again later.</div>}
                {data && <div>Your ticket has been submitted successfully.</div>}
              </>
            )}
          </Formik>
        )}
      </Mutation>
    </>
  );
};

const mapStateToProps = ({ auth: { verified }, type: { lightMode } }) => ({ verified, lightMode });

export default connect(mapStateToProps)(withRouter(SupportTicketForm));
