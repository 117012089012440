import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Paper, Grid, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { HelpOutline, PersonOutlined, DescriptionOutlined, AlarmOn } from '@mui/icons-material';
import { verifyEmail } from 'actions/auth/emailVerify';

const allowedFeatures = [
  {
    icon: <HelpOutline />,
    title: 'Raise support tickets'
  },
  {
    icon: <PersonOutlined />,
    title: 'Update your profile'
  },
  {
    icon: <DescriptionOutlined />,
    title: 'View your monthly reports'
  },
  {
    icon: <AlarmOn />,
    title: 'More features coming soon...'
  }
];

const EmailVerificationPage = ({ route, verified, verifyEmail }) => {
  const [isVerified, setIsVerified] = useState(verified);
  const [isLoading, setIsLoading] = useState(false);

  const { t: token } = queryString.parse(route.location.search);

  useEffect(() => {
    const doVerify = async () => {
      setIsLoading(true);
      const result = await verifyEmail(token);
      setIsVerified(result);
      setIsLoading(false);
    };

    if (!verified) {
      doVerify();
    } else {
      setIsVerified(true);
    }
  }, [token, verifyEmail, verified]);

  if (isLoading) {
    return (
      <Typography component="h1" variant="h4">
        Verifying...
      </Typography>
    );
  }

  return isVerified ? (
    <Grid container>
      <Grid item xs={4}></Grid>
      <Grid item xs={4}>
        <Typography component="h1" variant="h4">
          Congratulations!
        </Typography>
        <Typography gutterBottom component="p">
          You have now verified your email address. You can now do the following things:
        </Typography>

        <Paper>
          <List>
            {allowedFeatures.map(({ icon, title }, index) => (
              <ListItem key={index}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={title} />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={4}></Grid>
    </Grid>
  ) : (
    <Typography component="h1" variant="h4">
      You are not verified
    </Typography>
  );
};

const mapStateToProps = ({ auth: { verified } }) => ({ verified });

export default connect(mapStateToProps, { verifyEmail })(EmailVerificationPage);
