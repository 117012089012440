import styled from 'styled-components';
import { CardMedia, Paper } from '@mui/material';
import { grey } from '@mui/material/colors';

export const StyledPaper = styled(Paper)`
  padding: 0 !important;
`;

export const StyledPaperInner = styled.div`
  padding: ${(p) => p.theme.spacing(2)};
`;

export const StyledCardMedia = styled(CardMedia)`
  height: 0;
  padding-bottom: 50%;
  width: 100%;
`;

export const NoImageWrapper = styled.div`
  color: ${grey[900]};
  height: 0;
  padding-bottom: 50%;
  width: 100%;

  .MuiSvgIcon-root {
    font-size: 9rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`;
