import React, { useState } from 'react';
import { startCase } from 'lodash';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { Button, Grid } from '@mui/material';
import { StyledAppBar, ImgAvatar, StyledChip, StyledTabs, StyledTab } from './styles';
import { LIST_ALL_USERS_QUERY } from './data';
import Table from '../../../../components/Table';
import PageLoader from '../../../../components/Loaders/PageLoader';
import StaticIntro from '../../../../components/StaticIntro';
import * as ROUTES from '../../../../constants/routes';

const UsersPage = () => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <StaticIntro pretitle="Management" title="Users" />

      <Query query={LIST_ALL_USERS_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <PageLoader />;
          if (error) return 'Currently unavailable';

          const { getAllUsers } = data || {};

          let role = 'normal';

          if (tab === 1) {
            role = 'superAdmin';
          }

          return (
            <Grid container>
              <Grid item xs={12}>
                <StyledAppBar position="static">
                  <StyledTabs value={tab} onChange={handleTabChange}>
                    <StyledTab label="Users" />
                    <StyledTab label="Admins" />
                  </StyledTabs>
                </StyledAppBar>

                <Table
                  title="Manage Users"
                  columns={[
                    { title: 'Avatar', field: 'avatar' },
                    { title: 'First Name', field: 'firstname' },
                    { title: 'Last Name', field: 'lastname' },
                    { title: 'Email', field: 'email' },
                    { title: 'Role', field: 'role' },
                    { title: 'Projects', field: 'projectCount' },
                    { title: 'Verified', field: 'verified' },
                    { title: 'Actions', field: 'userProfile' }
                  ]}
                  data={getAllUsers
                    .filter((user) => user.role === role)
                    .map(({ uid, firstname, lastname, role, email, emailVerified, projectCount }, index) => {
                      return {
                        avatar: <ImgAvatar email={email} size={38} default="mp" />,
                        firstname,
                        lastname,
                        email,
                        role: startCase(role),
                        projectCount,
                        verified: (
                          <StyledChip
                            label={emailVerified ? 'Verified' : 'Not Verified'}
                            size="small"
                            variant="outlined"
                            verified={emailVerified.toString()}
                          />
                        ),
                        userProfile: (
                          <Button
                            component={Link}
                            variant="outlined"
                            size="small"
                            to={`${ROUTES.DASHBOARD_USER_PROFILE_ROOT}/${uid}`}
                          >
                            View
                          </Button>
                        )
                      };
                    })}
                  options={{
                    pageSize: getAllUsers.length < 20 ? getAllUsers.length : 20,
                    sorting: true,
                    draggable: false
                  }}
                  style={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0
                  }}
                />
              </Grid>
            </Grid>
          );
        }}
      </Query>
    </>
  );
};

export default UsersPage;
