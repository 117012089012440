import React from 'react';
import { connect } from 'react-redux';
import FormHeader from 'components/FormHeader';
import PasswordForgetForm from 'components/Forms/PasswordForgetForm';

const PasswordForgetPage = ({ lightMode }) => (
  <>
    <FormHeader
      forceColor="dark"
      lightState={lightMode}
      title="Forgot your Password?"
      subtitle="Enter your email and we send you a password reset link."
    />
    <PasswordForgetForm lightState={lightMode} />
  </>
);

const mapStateToProps = ({ type: { lightMode } }) => ({ lightMode });

export default connect(mapStateToProps)(PasswordForgetPage);
