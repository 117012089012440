import { Link } from 'react-router-dom';
import { startCase } from 'lodash';
import { Button, Grid, Typography } from '@mui/material';
import { Query } from '@apollo/client/react/components';
import { grey } from '@mui/material/colors';
import { StyledDescription, StyledChip, TicketIntro } from './styles';
import { SUPPORT_TICKET_QUERY } from './data';
import AddCommentToTicket from 'components/Forms/AddCommentToTicket';
import AttachmentThumbs from 'components/Attachments/AttachmentThumbs';
import LoadingSpinner from 'components/Loaders/LoadingSpinner';
import Comments from './Comments';
import * as ROUTES from 'constants/routes';

const TasksPage = ({ id }) => {
  return (
    <Grid container>
      <Grid item xs={12} md={3}></Grid>
      <Grid item xs={12} md={6}>
        <Query query={SUPPORT_TICKET_QUERY} variables={{ id }}>
          {({ loading, error, data }) => {
            if (loading) return <LoadingSpinner />;
            if (error) {
              return (
                <>
                  <Typography component="h2" variant="h4" gutterBottom>
                    This ticket does not exist
                  </Typography>
                  <Typography component="p" variant="body1" gutterBottom>
                    Please go back to your tickets and select one.
                  </Typography>
                  <Button component={Link} to={`${ROUTES.DASHBOARD_SUPPORT}`} variant="contained" color="primary">
                    Go to tickets
                  </Button>
                </>
              );
            }

            const {
              support: { ticket }
            } = data || {};

            if (ticket === null) {
              return null;
            }

            return (
              <>
                <TicketIntro>
                  <Typography component="h6" variant="body2" color={grey[500]}>
                    {ticket.key}
                  </Typography>
                  <Typography component="h1" variant="h4" gutterBottom>
                    {ticket.fields.summary}
                  </Typography>
                  <Typography component="h4" variant="h6" gutterBottom>
                    <StyledChip
                      label={startCase(ticket.fields.status.name)}
                      variant="outlined"
                      open={
                        ticket.fields.status.name !== 'Resolved' && ticket.fields.status.name !== 'Done' ? true : false
                      }
                    />
                  </Typography>

                  <Grid container>
                    <Grid item xs={12}>
                      <StyledDescription
                        dangerouslySetInnerHTML={{
                          __html: ticket.fields.description
                        }}
                      />
                    </Grid>
                  </Grid>
                </TicketIntro>

                {ticket.fields.status.name !== 'Resolved' || ticket.fields.status.name !== 'Done' ? (
                  <>
                    <Typography component="h4" variant="h6">
                      Add a Response:
                    </Typography>
                    <AddCommentToTicket ticketId={ticket.id} />
                  </>
                ) : (
                  <TicketIntro>
                    <Typography component="h4" variant="h6" gutterBottom>
                      This ticket has been closed.
                    </Typography>
                    <Typography component="p" gutterBottom>
                      You can create a follow up ticket by clicking the button below.
                    </Typography>
                    <Button
                      component={Link}
                      to={`${ROUTES.DASHBOARD_SUPPORT_CREATE}?f=${ticket.id}`}
                      variant="contained"
                      color="primary"
                    >
                      Create Follow Up Ticket
                    </Button>
                  </TicketIntro>
                )}

                <AttachmentThumbs attachments={ticket.fields.attachment} removeFile={false} title="Attachments" />

                <Comments comments={ticket.fields.comment.comments} />
              </>
            );
          }}
        </Query>
      </Grid>
      <Grid item xs={12} md={3}></Grid>
    </Grid>
  );
};

export default TasksPage;
