import gql from 'graphql-tag';

export const LIST_ALL_USERS_QUERY = gql`
  {
    getAllUsers {
      uid
      email
      emailVerified
      role
      firstname
      lastname
      projectCount
    }
  }
`;
