import React from 'react';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { StyledIconButton } from './styles';

const MenuToggleButton = ({ open, handleDrawerClose, handleDrawerOpen }) =>
  open ? (
    <StyledIconButton onClick={handleDrawerClose} color="primary">
      <ChevronLeft />
    </StyledIconButton>
  ) : (
    <StyledIconButton onClick={handleDrawerOpen} color="primary">
      <ChevronRight />
    </StyledIconButton>
  );

export default MenuToggleButton;
