import React from 'react';
import styled from 'styled-components';
import { Button, Dialog, Fab } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import { grey } from '@mui/material/colors';

export const StyledDialog = styled(({ ...other }) => <Dialog classes={{ paper: 'paper' }} {...other} />)`
  & .paper {
    padding: ${(p) => p.theme.spacing(4)};
  }
`;

export const StyledCard = styled(({ lightMode, ...other }) => <Button {...other} />)`
  background: ${(p) => (p.lightMode ? grey[300] : p.theme.palette.common.black)};
  border-radius: 4px;
  color: ${(p) => (p.lightMode ? p.theme.palette.common.black : grey[300])};
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 375px;
  overflow: hidden;
  padding: 0;
  width: 100%;

  .MuiCardActions-root {
    margin-top: auto;
  }
`;

export const StyledCardHeaderIcon = styled.div`
  left: 50%;
  opacity: 0.3;
  position: absolute;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
`;

export const PositionedIcon = styled(AddCircleOutline)`
  height: auto;
  width: 40px !important;
`;

export const StyledFab = styled(Fab)`
  bottom: ${(p) => p.theme.spacing(2)};
  position: fixed;
  right: ${(p) => p.theme.spacing(2)};
`;
