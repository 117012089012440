import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Mutation } from '@apollo/client/react/components';
import { Button, Divider, Dialog, DialogContent, DialogContentText, Typography, TextField } from '@mui/material';
import { Block, DeleteOutline, GetApp } from '@mui/icons-material';
import {
  DeleteIconButton,
  StyledPaper,
  StyledPaperInner,
  StyledDialogTitle,
  InlineCode,
  CenterContent
} from '../styles';
import { DELETE_USER_MUTATION } from './data';
import { LIST_ALL_USERS_QUERY } from 'views/Dashboard/SuperAdmin/UsersPage/data';
import LoadingSpinner from 'components/Loaders/LoadingSpinner';
import * as ROUTES from 'constants/routes';

const UserManagementActions = ({ uid, email, currentUserEmail }) => {
  const [open, setOpen] = useState(false);
  const [confirmUser, setConfirmUser] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmationChange = (email) => {
    setConfirmUser(email);
  };

  return (
    <StyledPaper>
      <StyledPaperInner>
        <Typography variant="h6" component="h2">
          Other actions
        </Typography>
      </StyledPaperInner>
      <Divider />
      <StyledPaperInner>
        <Button startIcon={<Block />}>Suspend user account</Button>
        <br />
        <Button startIcon={<GetApp />}>Export client data</Button>
        <br />
      </StyledPaperInner>
      <Divider />
      <StyledPaperInner>
        {currentUserEmail === email ? (
          <Typography variant="caption">Sorry but you are not able to delete yourself.</Typography>
        ) : (
          <>
            <Typography variant="caption">
              Remove this this customer’s data if he requested that, if not please be aware that what has been deleted
              can never brought back
            </Typography>
            <br />
            <br />

            <DeleteIconButton startIcon={<DeleteOutline />} variant="contained" onClick={handleClickOpen}>
              Delete user account
            </DeleteIconButton>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
              <StyledDialogTitle id="form-dialog-title">Confirm User Deletion</StyledDialogTitle>
              <Mutation mutation={DELETE_USER_MUTATION}>
                {(deleteUser, { loading, error, data }) => (
                  <DialogContent>
                    {!data && !loading && (
                      <>
                        <DialogContentText>
                          If you are sure you want to delete <InlineCode>{email}</InlineCode>, confirm deletion of the
                          user by entering their email address in the input below.
                          <TextField
                            variant="outlined"
                            name="confirmuser"
                            label="Confirm User"
                            margin="normal"
                            required
                            fullWidth
                            onChange={(event) => handleConfirmationChange(event.target.value)}
                          />
                        </DialogContentText>

                        <DeleteIconButton
                          disabled={confirmUser === email ? false : true}
                          variant="contained"
                          onClick={(e) => {
                            deleteUser({
                              variables: { uid },
                              refetchQueries: [{ query: LIST_ALL_USERS_QUERY }]
                            });
                          }}
                        >
                          Confirm customer deletion
                        </DeleteIconButton>

                        <Button onClick={handleClose} variant="container" color="primary">
                          Cancel
                        </Button>
                      </>
                    )}

                    {loading && (
                      <CenterContent>
                        <DialogContentText>User is being deleted...</DialogContentText>
                        <LoadingSpinner />
                      </CenterContent>
                    )}

                    {data && (
                      <CenterContent>
                        <DialogContentText>
                          This user has now been deleted.
                          <br />
                          You can now return to the user list.
                        </DialogContentText>
                        <Button
                          variant="contained"
                          color="primary"
                          component={Link}
                          to={ROUTES.DASHBOARD_SUPER_ADMIN_USERS}
                        >
                          Go back to customer list
                        </Button>
                      </CenterContent>
                    )}

                    {error && <p>Unable to delete customers account.</p>}
                  </DialogContent>
                )}
              </Mutation>
            </Dialog>
          </>
        )}
      </StyledPaperInner>
    </StyledPaper>
  );
};

const mapStateToProps = ({ auth: { email } }) => ({ currentUserEmail: email });

export default connect(mapStateToProps)(UserManagementActions);
