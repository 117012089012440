import React from 'react';
import { Divider, Typography, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { StyledPaper, StyledPaperInner } from '../styles';

const AgencyDetailsTable = ({ name }) => {
  return (
    <StyledPaper>
      <StyledPaperInner>
        <Typography variant="h6" component="h2">
          Agency Details
        </Typography>
      </StyledPaperInner>
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell scope="row">Name</TableCell>
            <TableCell>{name}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </StyledPaper>
  );
};

export default AgencyDetailsTable;
