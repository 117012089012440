import styled from 'styled-components';
import { Paper, ListItemIcon } from '@mui/material';

export const StyledPaper = styled(Paper)`
  padding: 0 !important;
`;

export const StyledPaperInner = styled.div`
  padding: ${(p) => p.theme.spacing(2)};
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  left: 16px;
  position: relative;
`;
