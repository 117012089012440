import { FETCH_USER, SIGNUP_SUCCESS, LOGIN_SUCCESS, SIGNOUT_SUCCESS, VERIFY_USER_EMAIL } from 'actions/types';

/** Initial state */
const initState = {
  authError: null,
  regComplete: false,
  firstname: null,
  lastname: null,
  email: null,
  role: null,
  verified: false,
  loggedIn: false,
  projectCount: 0,
  agencies: null,
  projects: null
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_USER:
      return {
        ...state,
        firstname: action.data.firstname,
        lastname: action.data.lastname,
        email: action.data.email,
        role: action.data.role,
        verified: action.data.verified,
        loggedIn: true,
        projectCount: action.data.projectCount,
        agencies: action.data.agencies,
        projects: action.data.projects
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        firstname: action.data.firstname,
        lastname: action.data.lastname,
        email: action.data.email,
        role: action.data.role,
        verified: action.data.verified,
        loggedIn: true,
        projectCount: action.data.projectCount,
        agencies: action.data.agencies,
        projects: action.data.projects
      };

    case SIGNOUT_SUCCESS:
      return {
        ...state,
        authError: null,
        firstname: null,
        lastname: null,
        email: null,
        role: null,
        loggedIn: false,
        projectCount: 0,
        agencies: null,
        projects: null
      };

    case SIGNUP_SUCCESS:
      return {
        ...state,
        authError: null,
        firstname: action.data.firstname,
        lastname: action.data.lastname,
        email: action.data.email,
        role: action.data.role,
        verified: action.data.verified,
        projectCount: action.data.projectCount,
        loggedIn: true,
        regComplete: true,
        agencies: action.data.agencies,
        projects: action.data.projects
      };

    case VERIFY_USER_EMAIL:
      return {
        ...state,
        verified: action.data.verified
      };

    default:
      return state;
  }
};

export default authReducer;
