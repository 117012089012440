import React from 'react';
import SimpleCard from 'components/Cards/SimpleCard';

const ContactCard = () => (
  <SimpleCard
    title="It looks like you have no projects"
    description="Please get in touch with AndAnotherDay to start your first project."
    link="#"
    linkText="Contact us"
  />
);

export default ContactCard;
