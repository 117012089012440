import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';
import { doSignOut } from 'actions/auth/signOut';
import { SignOutButton } from './styles';

const SignOut = ({ doSignOut, client }) => {
  const logout = () => {
    /** Dispatch to redux */
    doSignOut();

    /** Clear the Apollo store */
    client.clearStore();
  };

  return (
    <SignOutButton fullWidth variant="outlined" onClick={logout}>
      Sign out
    </SignOutButton>
  );
};

SignOut.propTypes = {
  doSignOut: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired
};

export default withApollo(connect(null, { doSignOut })(SignOut));
