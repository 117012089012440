import React from 'react';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { Button, Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import { BrokenImage } from '@mui/icons-material';
import { StyledCardMedia, NoImageWrapper } from './styles';
import { GET_PROJECT_QUERY } from './data';
import PageLoader from 'components/Loaders/PageLoader';
import StaticIntro from 'components/StaticIntro';
import ProjectDetailsTable from './ProjectDetailsTable';
import ProjectMonitoringCard from 'components/Cards/ProjectMonitoringCard';
import ReportsListCard from 'components/Cards/ReportsListCard';
import * as ROUTES from 'constants/routes';

const ProjectDetailsPage = ({ route }) => {
  const id = route.match.params.id;

  const updateProjectImage = () => {
    console.log('Updating image...');
  };

  return (
    <>
      <StaticIntro pretitle="Management" title="Project Overview" />

      <Query query={GET_PROJECT_QUERY} variables={{ id }}>
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={8}>
                  <PageLoader />
                </Grid>
              </Grid>
            );
          }

          if (error) {
            return (
              <>
                <Typography gutterBottom>
                  We have encountered an issue trying to fetch this project. Please return to the project list.
                </Typography>
                <Button component={Link} to={ROUTES.DASHBOARD_SUPER_ADMIN_PROJECTS} variant="contained" color="primary">
                  Go back to project list
                </Button>
              </>
            );
          }

          const { project } = data || {};

          return (
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={8}>
                <ProjectDetailsTable data={data} />

                <ProjectMonitoringCard monitors={project.monitor} />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Card style={{ marginBottom: '32px' }}>
                  <CardActionArea onClick={updateProjectImage}>
                    {project.screenshot.secure_url ? (
                      <StyledCardMedia image={project.screenshot.secure_url} title={`${project.title} screenshot`} />
                    ) : (
                      <NoImageWrapper>
                        <BrokenImage />
                      </NoImageWrapper>
                    )}
                  </CardActionArea>
                  <CardContent style={{ paddingBottom: '16px' }}>
                    <Typography color="textSecondary" component="p">
                      Click on the cover image above to update it.
                    </Typography>
                  </CardContent>
                </Card>

                <ReportsListCard reports={project.reports} />
              </Grid>
            </Grid>
          );
        }}
      </Query>
    </>
  );
};

export default ProjectDetailsPage;
