import React, { useState } from 'react';
import { Form, Field, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { validationSchema } from './validation';

const UpdateUserDetailsForm = ({ user: { firstname, lastname, email } }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  return (
    <>
      <Button startIcon={<Edit />} onClick={() => setOpen(true)}>
        Edit
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit User Details</DialogTitle>

        <Formik
          initialValues={{
            firstname,
            lastname,
            email
          }}
          validationSchema={validationSchema}
          onSubmit={({ firstname, lastname, email }) => console.log(firstname, lastname, email)}
        >
          {() => (
            <Form noValidate>
              <DialogContent>
                <DialogContentText>Use the form below to update the users details.</DialogContentText>

                <Field
                  component={TextField}
                  variant="outlined"
                  name="firstname"
                  label="First name"
                  margin="normal"
                  required
                  fullWidth
                />
                <Field
                  component={TextField}
                  variant="outlined"
                  name="lastname"
                  label="Last name"
                  margin="normal"
                  required
                  fullWidth
                />
                <Field
                  component={TextField}
                  variant="outlined"
                  name="email"
                  label="Email"
                  margin="normal"
                  required
                  fullWidth
                  disabled
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Update
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default UpdateUserDetailsForm;
