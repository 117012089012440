import React from 'react';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { Divider, Typography, Container, Grid, Link as InlineLink, Box } from '@mui/material';
import { DASHBOARD_FOOTER_QUERY } from './data';
import * as ROUTES from 'constants/routes';

const DashboardFooter = () => (
  <Query query={DASHBOARD_FOOTER_QUERY}>
    {({ loading, error, data }) => {
      if (loading || error) return null;

      // const {
      //   content: {
      //     dashboard: { footer }
      //   }
      // } = data || {};

      const footers = [
        {
          title: 'Company',
          description: [
            {
              title: 'Team',
              url: 'https://andanotherday.com/blog/its-oh-so-quiet-where-have-we-been-since-april/',
              external: true
            },
            {
              title: 'About us',
              url: 'https://andanotherday.com/about/',
              external: true
            },
            {
              title: 'Contact us',
              url: 'https://andanotherday.com/contact/',
              external: true
            },
            {
              title: 'Blog',
              url: 'https://andanotherday.com/blog/',
              external: true
            }
          ]
        },
        {
          title: 'Social',
          description: [
            {
              title: 'Twitter',
              url: 'https://twitter.com/_AndAnotherDay',
              external: true
            },
            {
              title: 'Facebook',
              url: 'https://www.facebook.com/andanotherday/',
              external: true
            },
            {
              title: 'LinkedIn',
              url: 'https://www.linkedin.com/company/andanotherday',
              external: true
            }
          ]
        },
        {
          title: 'Features',
          description: [
            {
              title: 'Manage projects',
              url: ROUTES.DASHBOARD_PROJECTS
            },
            {
              title: 'View support',
              url: ROUTES.DASHBOARD_SUPPORT
            },
            {
              title: 'Raise support',
              url: ROUTES.DASHBOARD_SUPPORT_CREATE
            }
          ]
        },
        {
          title: 'Legal',
          description: [
            {
              title: 'Privacy policy',
              url: ROUTES.PRIVACY
            },
            {
              title: 'Terms of use',
              url: ROUTES.TERMS
            }
          ]
        }
      ];

      return (
        <>
          <Divider />
          <Box mt={5} mb={5}>
            <Container maxWidth="md" component="footer">
              <Grid container spacing={4} justify="space-evenly">
                {footers.map(({ title, description }, index) => (
                  <Grid item xs={6} sm={3} key={index}>
                    <Typography variant="h6" color="textPrimary" gutterBottom>
                      {title}
                    </Typography>

                    <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
                      {description.map(({ title, url, external }, index) => (
                        <li key={index}>
                          {url ? (
                            <>
                              {external ? (
                                <InlineLink href={url} target="_blank" variant="subtitle1" color="textSecondary">
                                  {title}
                                </InlineLink>
                              ) : (
                                <InlineLink component={Link} to={url} variant="subtitle1" color="textSecondary">
                                  {title}
                                </InlineLink>
                              )}
                            </>
                          ) : (
                            <Typography variant="subtitle1" color="textSecondary">
                              {title}
                            </Typography>
                          )}
                        </li>
                      ))}
                    </ul>
                  </Grid>
                ))}
              </Grid>
              <Box mt={5}>
                <Typography variant="body2" color="textSecondary" align="center">
                  {'Copyright © '}
                  <InlineLink color="inherit" target="_blank" href="https://andanotherday.com/">
                    AndAnotherDay Ltd
                  </InlineLink>{' '}
                  {new Date().getFullYear()}
                  {'.'}
                </Typography>
              </Box>
            </Container>
          </Box>
        </>
      );
    }}
  </Query>
);

export default DashboardFooter;
