import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Typography, CardHeader } from '@mui/material';
import {
  StyledCard,
  StyledCardMedia,
  StyledCardHeaderIcon,
  PositionedIcon,
  StyledCardContent,
  StyledCardActions,
  ExternalLink,
  Icon
} from './styles';
import * as ROUTES from 'constants/routes';

const ProjectCard = ({ project: { id, title, summary, screenshot, websiteUrl }, lightMode }) => (
  <StyledCard>
    {screenshot && screenshot.secure_url ? (
      <StyledCardMedia image={screenshot.secure_url} title={title} />
    ) : (
      <StyledCardHeaderIcon lightMode={lightMode}>
        <PositionedIcon />
      </StyledCardHeaderIcon>
    )}
    <CardHeader title={title} />
    <StyledCardContent>
      <Typography variant="body2" color="textSecondary" component="p">
        {summary}
      </Typography>
    </StyledCardContent>
    <StyledCardActions>
      <Button variant="contained" component={Link} to={`${ROUTES.DASHBOARD_PROJECT_ROOT}/${id}`} color="primary">
        View project
      </Button>
      <ExternalLink href={websiteUrl} target="_blank" rel="noopener noreferrer">
        <Button variant="outlined" color="primary">
          Visit site <Icon />
        </Button>
      </ExternalLink>
    </StyledCardActions>
  </StyledCard>
);

/** Type checking */
ProjectCard.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  summary: PropTypes.string,
  screenshot: PropTypes.string,
  websiteUrl: PropTypes.string,
  lightMode: PropTypes.bool
};

const mapStateToProps = ({ type: { lightMode } }) => ({ lightMode });

export default connect(mapStateToProps)(ProjectCard);
