import Gravatar from 'react-gravatar';
import styled from 'styled-components';
import { AppBar, Chip, Tabs, Tab } from '@mui/material';
import { red, green, yellow } from '@mui/material/colors';

export const StyledAppBar = styled(AppBar)`
  background-color: ${(p) => p.theme.palette.common.black};
`;

export const ImgAvatar = styled(Gravatar)`
  border-radius: 100%;
  float: left;
  margin-right: ${(p) => p.theme.spacing(2)};
`;

export const StyledChip = styled(Chip)`
  border-color: ${(p) => (p.verified === 'true' ? green[700] : red[700])} !important;
  color: ${(p) => (p.verified === 'true' ? green[700] : red[700])} !important;
`;

export const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: ${yellow[600]} !important;
  }
`;

export const StyledTab = styled(Tab)`
  color: ${(p) => p.theme.palette.common.white};

  &.Mui-selected {
    color: ${yellow[600]} !important;
  }
`;
