import gql from 'graphql-tag';

export const LIST_ALL_PROJECTS_QUERY = gql`
  {
    getAllProjects {
      id
      title
      websiteUrl
      projectType
      projectCode
      ssl {
        valid
        message
      }
      screenshot {
        secure_url
      }
    }
  }
`;
