import styled from 'styled-components';
import { Box } from '@mui/material';

export const StyledBox = styled(Box)`
  background: ${(p) =>
    p.color === 'red'
      ? 'linear-gradient(135deg, #FE6B8B 30%, #FF8E53 90%)'
      : p.color === 'green'
      ? 'linear-gradient(135deg, #11998e 30%, #56B4D3 90%)'
      : 'linear-gradient(135deg, #2196F3 30%, #21CBF3 90%)'};
  border-radius: ${(p) => p.theme.spacing(1)};
  color: ${(p) => p.theme.palette.common.white};
  display: inline-block;
  height: 100%;
  padding: ${(p) => p.theme.spacing(4)};
  text-align: center;
  text-decoration: none;
  width: 100%;
`;
