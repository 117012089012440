import styled from 'styled-components';
import { Paper } from '@mui/material';

export const DropZoneContainer = styled(Paper)`
  border: 1px dashed #4d4d4d !important;
  margin-bottom: ${(p) => p.theme.spacing(1)} !important;
  margin-top: ${(p) => p.theme.spacing(2)} !important;
  outline: none;
`;

export const AttachmentsContainer = styled.aside`
  display: inline-block;
  margin-top: ${(p) => p.theme.spacing(2)};
  width: 100%;
`;

export const DropText = styled.p`
  color: #adadad;
  padding: ${(p) => p.theme.spacing(2)};
`;
