import gql from 'graphql-tag';

export const SUPPORT_TICKET_QUERY = gql`
  query getSupportTicket($id: ID!) {
    support {
      ticket(id: $id) {
        id
        key
        fields {
          summary
          description
          status {
            name
          }
          attachment {
            filename
            thumbnail
            content
            mimeType
          }
          comment {
            comments {
              body
              created
              jsdPublic
              author {
                displayName
                accountType
              }
            }
          }
        }
      }
    }
  }
`;
