import React from 'react';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { Avatar, Button, Grid, Link as ExternalLink, Paper, Typography } from '@mui/material';
import { LIST_ALL_PROJECTS_QUERY } from './data';
import { history } from '../../../../helpers/History';
import Table from '../../../../components/Table';
import PageLoader from '../../../../components/Loaders/PageLoader';
import StaticIntro from '../../../../components/StaticIntro';
import SslChip from '../../../../components/SslChip';
import * as ROUTES from '../../../../constants/routes';

const ProjectsPage = () => {
  return (
    <>
      <StaticIntro pretitle="Management" title="Projects" />

      <Query query={LIST_ALL_PROJECTS_QUERY}>
        {({ loading, error, data }) => {
          if (loading) {
            return <PageLoader />;
          }

          if (error) {
            return (
              <Paper>
                <Typography component="h2" variant="h6" gutterBottom>
                  It appears we have encountered a problem
                </Typography>
                <Button color="primary" onClick={() => history.go(0)} variant="contained">
                  Click to reload
                </Button>
              </Paper>
            );
          }

          const { getAllProjects } = data || {};

          return (
            <Grid container>
              <Grid item xs={12}>
                <Table
                  title="Manage All Projects"
                  columns={[
                    { title: 'Preview', field: 'preview' },
                    { title: 'Title', field: 'title' },
                    { title: 'Domain', field: 'domain' },
                    { title: 'SSL', field: 'ssl' },
                    { title: 'Project Type', field: 'type' },
                    { title: 'Project Code', field: 'code' },
                    { title: 'Actions', field: 'link' }
                  ]}
                  data={
                    getAllProjects &&
                    getAllProjects.map(
                      ({ id, title, websiteUrl, projectType, screenshot, projectCode, ssl }, index) => {
                        return {
                          preview:
                            screenshot.secure_url === null ? (
                              <Avatar alt={title}>{title.substring(0, 1)}</Avatar>
                            ) : (
                              <Avatar alt={title} src={screenshot.secure_url} />
                            ),
                          title,
                          domain: (
                            <ExternalLink href={websiteUrl} rel="noopener noreferrer" target="_blank">
                              {websiteUrl}
                            </ExternalLink>
                          ),
                          ssl: <SslChip ssl={ssl} title={title} />,
                          type: projectType,
                          code: (
                            <ExternalLink
                              href={`${process.env.REACT_APP_JIRA_URL}${projectCode}`}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <Button variant="outlined" size="small" color="primary">
                                {projectCode}
                              </Button>
                            </ExternalLink>
                          ),
                          link: (
                            <Button
                              component={Link}
                              variant="contained"
                              color="primary"
                              size="small"
                              to={`${ROUTES.DASHBOARD_SUPER_ADMIN_PROJECT_ROOT}/${id}`}
                            >
                              View &amp; Edit
                            </Button>
                          )
                        };
                      }
                    )
                  }
                  options={{
                    pageSize: getAllProjects.length < 20 ? getAllProjects.length : 20,
                    pageSizeOptions: [getAllProjects.length, 5, 10, 20],
                    sorting: true,
                    draggable: false
                  }}
                  style={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0
                  }}
                />
              </Grid>
            </Grid>
          );
        }}
      </Query>
    </>
  );
};

export default ProjectsPage;
