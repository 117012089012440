import React from 'react';
import Gravatar from 'react-gravatar';
import styled from 'styled-components';
import { InputBase, List, Paper, Popover, IconButton } from '@mui/material';
import { Search } from '@mui/icons-material';
import { alpha } from '@mui/material/styles';

export const ImgAvatar = styled(Gravatar)`
  border-radius: 100%;
`;

export const LogoutWrapper = styled.div`
  padding: ${(p) => p.theme.spacing(2)};
`;

export const StyledPopover = styled(({ lightMode, ...other }) => <Popover classes={{ paper: 'paper' }} {...other} />)`
  & .paper {
    background: ${(p) => (p.lightMode ? p.theme.palette.common.white : '#1a1a1a')};
    padding: 0;
  }
`;

export const StyledList = styled(List)`
  .MuiListItem-root:not(:last-child) {
    border-bottom: 1px solid ${(p) => p.theme.palette.grey[200]};
  }
`;

export const StyledIconButton = styled(IconButton)`
  margin-left: ${(p) => p.theme.spacing(2)} !important;
`;

export const StyledIconButtonSmall = styled(IconButton)`
  margin-left: ${(p) => p.theme.spacing(2)} !important;
  padding: 0;
`;

/** Search Field */
export const SearchWrapper = styled.div`
  position: relative;
  background-color: transparent;
  margin-left: 0;
  width: 100%;

  ${(p) => p.theme.breakpoints.up('sm')} {
    width: auto;
  }
`;

export const SearchIcon = styled(Search)`
  padding: ${(p) => p.theme.spacing(0)};
  height: 100%;
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => (p.theme.palette.type === 'light' ? '#1a1a1a' : p.theme.palette.common.white)};
  margin-left: ${(p) => p.theme.spacing(1) / 2}px;
`;

export const SearchInputBase = styled(InputBase)`
  border-radius: ${(p) => p.theme.shape.borderRadius}px;
  color: ${(p) => p.theme.palette.common.white};
  padding-left: ${(p) => p.theme.spacing(4)};
  transition: ${(p) => p.theme.transitions.create('width')};
  width: 100%;

  ${(p) => p.theme.breakpoints.up('sm')} {
    width: 0;

    &.Mui-focused {
      background-color: ${(p) => alpha(p.theme.palette.common.black, 0.25)};
      width: 20ch;
    }
  }
`;

export const StyledPaper = styled(Paper)`
  padding: ${(p) => p.theme.spacing(2)};
`;
