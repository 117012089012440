export const FETCH_USER = 'FETCH_USER';
export const USER_LIGHT_MODE = 'USER_LIGHT_MODE';

export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SIGNOUT_ERROR = 'SIGNOUT_ERROR';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_FAILED';

export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';

export const VERIFY_USER_EMAIL = 'VERIFY_USER_EMAIL';

export const MENU_TOGGLE = 'MENU_TOGGLE';

export const SUPPORT_TICKET_COUNT = 'SUPPORT_TICKET_COUNT';
