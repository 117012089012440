import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { StyledBox } from './styles';

const ColouredBox = ({ number, link, title, color }) => (
  <StyledBox component={Link} to={link} color={color ? color : 'blue'}>
    <Typography component="h2" variant="h3">
      <strong>{number}</strong>
    </Typography>
    <Typography component="span" variant="h6">
      {title}
    </Typography>
  </StyledBox>
);

export default ColouredBox;
