import styled from 'styled-components';
import { Badge, Typography } from '@mui/material';
import { red } from '@mui/material/colors';

export const StyledBadge = styled(Badge)`
  .MuiBadge-badge {
    background: ${red[700]};
  }
`;

export const NotificationDescription = styled(Typography)`
  display: inline-block;
  max-width: 350px;
`;
