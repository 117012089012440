import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { ReactComponent as LogoSvg } from '../../assets/img/full-logo.svg';

export const Root = styled.div`
  flex-grow: 1;
`;

export const Container = styled(Grid)`
  height: 100vh;
`;

export const IntroColumn = styled(Grid)`
  background-color: ${(p) => p.theme.palette.common.black};
  padding: ${(p) => p.theme.spacing(2)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CenterContent = styled(Grid)`
  background: ${(p) => p.theme.palette.common.white};
  padding: ${(p) => p.theme.spacing(2)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logo = styled(LogoSvg)`
  fill: #ffffff;
  position: absolute;
  top: 120px;
  height: 60px;
`;

export const PolicyLinks = styled.div`
  bottom: 120px;
  position: absolute;
`;

export const StyledLink = styled(Link)`
  color: ${(p) => p.theme.palette.common.white};
  border-right: 1px solid ${(p) => p.theme.palette.common.white};
  margin-right: ${(p) => p.theme.spacing(2)};
  padding-right: ${(p) => p.theme.spacing(2)};
  text-decoration: none;

  &:last-child {
    border: none;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const IntroText = styled(Typography)`
  color: ${(p) => p.theme.palette.common.white} !important;
`;
