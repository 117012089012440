import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Phonelink } from '@mui/icons-material';
import { StyledPaper, StyledPaperInner, StyledListItemIcon } from './styles';

const ReportsListCard = ({ reports }) => {
  if (typeof reports == 'undefined' || reports.length === 0) {
    return null;
  }

  return (
    <StyledPaper>
      <StyledPaperInner>
        <Typography variant="h6" component="h2">
          Monthly Reports
        </Typography>
      </StyledPaperInner>
      <Divider />
      <List>
        {reports.map(({ id, reportPeriod, startDate, endDate }, index) => (
          <ListItem button component={Link} to={`/dashboard/report/${id}`} key={index}>
            <StyledListItemIcon>
              <Phonelink />
            </StyledListItemIcon>
            <ListItemText
              primary={`Report: ${reportPeriod}`}
              secondary={
                <>
                  <Moment format="Do MMMM YYYY">{startDate}</Moment> - <Moment format="Do MMMM YYYY">{endDate}</Moment>
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </StyledPaper>
  );
};

ReportsListCard.propTypes = {
  reports: PropTypes.array
};

export default ReportsListCard;
