import { ApolloClient, ApolloLink, InMemoryCache, Observable } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { authRef } from 'config/firebase';

const httpLink = new createUploadLink({
  uri: process.env.REACT_APP_BACKEND_URL
});

const authLink = new ApolloLink((operation, forward) => {
  return new Observable(async (observer) => {
    await authRef.onAuthStateChanged(async (user) => {
      if (user) {
        await user
          .getIdToken(true)
          .then((idToken) => {
            operation.setContext({
              headers: {
                authorization: idToken ? idToken : '',
                'Apollo-Require-Preflight': 'true'
              }
            });
          })
          .then(() => {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer)
            };
            forward(operation).subscribe(subscriber);
          })
          .catch((err) => observer.error(err));
      } else {
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer)
        };
        forward(operation).subscribe(subscriber);
      }
    });
  });
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

export default client;
