import styled from 'styled-components';
import { Typography } from '@mui/material';

export const PreTitle = styled(Typography)`
  color: ${(p) => p.theme.palette.grey[600]} !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-transform: uppercase;
`;

export const IntroContainer = styled.div`
  margin-bottom: ${(p) => p.theme.spacing(3)} !important;
`;
