import React from 'react';
import styled, { css } from 'styled-components';
import { Button, Stepper, StepConnector } from '@mui/material';
import { yellow } from '@mui/material/colors';

export const StyledStepper = styled(Stepper)`
  background: transparent;
  border: none;
  box-shadow: none !important;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${(p) => p.theme.spacing(4)};
`;

export const StyledButton = styled(Button)`
  margin-left: ${(p) => p.theme.spacing(1)};
`;

export const StyledStepConnector = styled(({ active, completed, ...other }) => (
  <StepConnector classes={{ line: 'line', alternativeLabel: 'alternative-label' }} {...other} />
))`
  &.alternative-label {
    top: 22px;
  }

  & .line {
    height: 3px;
    border: 0;
    background-color: #eaeaf0;
    border-radius: 1px;
  }

  ${(p) =>
    p.active &&
    css`
      .line {
        background-image: linear-gradient(135deg, ${yellow[800]} 30%, ${yellow[400]} 90%);
      }
    `}

  ${(p) =>
    p.completed &&
    css`
      .line {
        background-image: linear-gradient(135deg, ${yellow[800]} 30%, ${yellow[400]} 90%);
      }
    `}
`;

export const StyledIconWrapper = styled(({ active, completed, ...other }) => <div {...other} />)`
  background-color: #ccc;
  z-index: 1;
  color: #fff;
  width: 50px;
  height: 50px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;

  ${(p) =>
    p.active &&
    css`
      background-image: linear-gradient(135deg, ${yellow[800]} 30%, ${yellow[400]} 90%);
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
    `}

  ${(p) =>
    p.completed &&
    css`
      background-image: linear-gradient(135deg, ${yellow[800]} 30%, ${yellow[400]} 90%);
    `}
`;
