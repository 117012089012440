import React from 'react';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { StyledPaper, StyledPaperInner } from './styles';
import { GET_USER_QUERY } from './data';
import UserDetailsTable from './UserDetailsTable';
import UserManagementActions from './UserManagementActions';
import PageLoader from 'components/Loaders/PageLoader';
import StaticIntro from 'components/StaticIntro';
import * as ROUTES from 'constants/routes';

const UsersDetailsPage = ({ route }) => {
  const uid = route.match.params.uid;

  return (
    <>
      <StaticIntro pretitle="Management" title="Edit User" />

      <Query query={GET_USER_QUERY} variables={{ uid }}>
        {({ loading, error, data }) => {
          if (loading) {
            return <PageLoader />;
          }

          if (error) {
            return (
              <>
                <Typography gutterBottom>This user does not exist. Please return to the users list.</Typography>
                <Button component={Link} to={ROUTES.DASHBOARD_SUPER_ADMIN_USERS} variant="contained" color="primary">
                  Go back to customer list
                </Button>
              </>
            );
          }

          const {
            getUser: { uid, email }
          } = data || {};

          return (
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={3}>
                <UserDetailsTable data={data} />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <StyledPaper>
                  <StyledPaperInner>
                    <Typography variant="h6" component="h2">
                      Invoices/Billing
                    </Typography>
                  </StyledPaperInner>
                  <Divider />
                  <StyledPaperInner>
                    <Typography>Coming soon...</Typography>
                  </StyledPaperInner>
                </StyledPaper>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <StyledPaper>
                  <StyledPaperInner>
                    <Typography variant="h6" component="h2">
                      Send Emails
                    </Typography>
                  </StyledPaperInner>
                  <Divider />
                  <StyledPaperInner>
                    <Typography>Coming soon...</Typography>
                  </StyledPaperInner>
                </StyledPaper>
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <UserManagementActions uid={uid} email={email} />
              </Grid>
            </Grid>
          );
        }}
      </Query>
    </>
  );
};

export default UsersDetailsPage;
