import React from 'react';
import MaterialTable from 'material-table';
import {
  Add,
  Check,
  Clear,
  DeleteOutline,
  ChevronRight,
  Edit,
  SaveAlt,
  FilterList,
  Search,
  ChevronLeft,
  FirstPage,
  LastPage,
  ArrowUpward,
  Remove,
  ViewColumn
} from '@mui/icons-material';
import { MaterialTableWrapper } from './styles';

const Table = (props) => {
  const pageSizeUnique = [...new Set([props.options.pageSize, 5, 10, 20])];

  return (
    <MaterialTableWrapper border={props.border} lastColumnWide={props.lastColumnWide}>
      <MaterialTable
        {...props}
        icons={{
          Add,
          Check,
          Clear,
          Delete: DeleteOutline,
          DetailPanel: ChevronRight,
          Edit,
          Export: SaveAlt,
          Filter: FilterList,
          FirstPage,
          LastPage,
          NextPage: ChevronRight,
          PreviousPage: ChevronLeft,
          ResetSearch: Clear,
          Search,
          SortArrow: ArrowUpward,
          ThirdStateCheck: Remove,
          ViewColumn
        }}
        options={{
          ...props.options,
          pageSizeOptions: props.options.pageSizeOptions
            ? props.options.pageSizeOptions
            : pageSizeUnique.sort((a, b) => a - b)
        }}
      />
    </MaterialTableWrapper>
  );
};

export default Table;
