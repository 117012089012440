import { USER_LIGHT_MODE } from 'actions/types';

const userLightMode = (value) => (dispatch) => {
  /** Dispatch for instant user response */
  dispatch({
    type: USER_LIGHT_MODE,
    data: {
      lightMode: value
    }
  });
};

export default userLightMode;
