import React from 'react';
import { StyledIcon, UnavailableWrapper } from './styles';

const PageUnavailable = ({ message }) => {
  return (
    <UnavailableWrapper>
      <StyledIcon />

      {message ? message : 'Unable to load content. Please try again later.'}
    </UnavailableWrapper>
  );
};

export default PageUnavailable;
