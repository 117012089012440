import styled from 'styled-components';
import { Paper, Grid, ListItem } from '@mui/material';

export const StyledContainer = styled(Grid)`
  padding-bottom: ${(p) => p.theme.spacing(8)} !important;
  padding-top: ${(p) => p.theme.spacing(8)} !important;
`;

export const StyledPaper = styled(Paper)`
  padding: 0 !important;
`;

export const StyledPaperInner = styled.div`
  padding: ${(p) => p.theme.spacing(2)};
`;

export const StyledListItem = styled(ListItem)`
  padding-left: ${(p) => p.theme.spacing(4)} !important;
  padding-right: ${(p) => p.theme.spacing(4)} !important;
`;
