import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { StyledDialogActions } from './styles';
import CodeMessage from 'components/Messages/CodeMessage';

const SslDialog = ({ title, message, open, setOpen }) => (
  <Dialog open={open} onClose={() => setOpen(false)}>
    <DialogTitle>Error with SSL for {title}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        It appears there is an issue with the SSL for this project. This should be investigated and rectified. Below is
        some information about the issue:
      </DialogContentText>
      <CodeMessage code={message} />
    </DialogContent>
    <StyledDialogActions>
      <Button onClick={() => setOpen(false)} color="primary" variant="contained">
        This will be investigated
      </Button>
    </StyledDialogActions>
  </Dialog>
);

/** Type checking */
SslDialog.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};

export default SslDialog;
