/** Templates */
import SiteTemplate from 'templates/SiteTemplate';
import AuthTemplate from 'templates/AuthTemplate';
import DashboardTemplate from 'templates/DashboardTemplate';

/** Site */
import HomePage from 'views/Site/HomePage';

/** Session Pages */
import SignInPage from 'views/Auth/SignIn';
import SignUpPage from 'views/Auth/SignUp';
import PasswordForgetPage from 'views/Auth/PasswordForget';

/** Dashboard */
import Dashboard from 'views/Dashboard';
import Projects from 'views/Dashboard/Projects';
import Project from 'views/Dashboard/Project';
import ProjectTaskPage from 'views/Dashboard/Project/ProjectTaskPage';
import ProjectAdd from 'views/Dashboard/Project/Add';
import Settings from 'views/Dashboard/Settings';
import EmailVerificationPage from 'views/Dashboard/User/EmailVerificationPage';

/** Support */
import Support from 'views/Dashboard/Support';
import SupportSolved from 'views/Dashboard/Support/SupportSolved';
import SupportClosed from 'views/Dashboard/Support/SupportClosed';
import SupportCreate from 'views/Dashboard/Support/SupportCreate';
import SupportTicketPage from 'views/Dashboard/Support/SupportTicketPage';

/** Agency Admin */
import UsersAdminPage from 'views/Dashboard/Admin/UsersPage';
import ProjectsAdminPage from 'views/Dashboard/Admin/ProjectsPage';

/** Super Admin */
import UsersPage from 'views/Dashboard/SuperAdmin/UsersPage';
import UserProfile from 'views/Dashboard/User/Profile';
import ProjectsPage from 'views/Dashboard/SuperAdmin/ProjectsPage';
import ProjectDetailsPage from 'views/Dashboard/SuperAdmin/ProjectDetailsPage';
import AgenciesPage from 'views/Dashboard/SuperAdmin/AgenciesPage';
import AgencyDetailsPage from 'views/Dashboard/SuperAdmin/AgencyDetailsPage';

/** No match */
import NoMatch from 'views/Site/NoMatch';

import * as ROUTES from 'constants/routes';

const routes = [
  /** Site */
  {
    path: ROUTES.HOME,
    component: HomePage,
    template: SiteTemplate
  },

  /** Auth */
  {
    path: ROUTES.AUTH_SIGN_IN,
    component: SignInPage,
    template: AuthTemplate,
    title: 'Welcome',
    description: 'Manage your AndAnotherDay projects'
  },
  {
    path: ROUTES.AUTH_SIGN_UP,
    component: SignUpPage,
    template: AuthTemplate,
    title: 'Register',
    description: 'Sign up for your account with AndAnotherDay to manage your projects'
  },
  {
    path: ROUTES.AUTH_PASSWORD_FORGET,
    component: PasswordForgetPage,
    template: AuthTemplate,
    title: 'Password reset',
    description: 'Fill out the form'
  },

  /** Dashboard */
  {
    path: ROUTES.DASHBOARD,
    component: Dashboard,
    template: DashboardTemplate,
    restricted: true
  },

  /** Projects */
  {
    path: ROUTES.DASHBOARD_PROJECTS,
    component: Projects,
    template: DashboardTemplate,
    restricted: true
  },
  {
    path: ROUTES.DASHBOARD_PROJECT_TASK,
    component: ProjectTaskPage,
    template: DashboardTemplate,
    restricted: true
  },
  {
    path: ROUTES.DASHBOARD_PROJECT_ADD,
    component: ProjectAdd,
    template: DashboardTemplate,
    restricted: true
  },
  {
    path: ROUTES.DASHBOARD_PROJECT,
    component: Project,
    template: DashboardTemplate,
    restricted: true
  },

  /** Support */
  {
    path: ROUTES.DASHBOARD_SUPPORT,
    component: Support,
    template: DashboardTemplate,
    restricted: true
  },
  {
    path: ROUTES.DASHBOARD_SUPPORT_CLOSED,
    component: SupportClosed,
    template: DashboardTemplate,
    restricted: true
  },
  {
    path: ROUTES.DASHBOARD_SUPPORT_SOLVED,
    component: SupportSolved,
    template: DashboardTemplate,
    restricted: true
  },
  {
    path: ROUTES.DASHBOARD_SUPPORT_CREATE,
    component: SupportCreate,
    template: DashboardTemplate,
    restricted: true
  },
  {
    path: ROUTES.DASHBOARD_SUPPORT_TICKET,
    component: SupportTicketPage,
    template: DashboardTemplate,
    restricted: true
  },

  /** Account */
  {
    path: ROUTES.DASHBOARD_ACCOUNT,
    component: Settings,
    template: DashboardTemplate,
    restricted: true
  },
  {
    path: ROUTES.DASHBOARD_ACCOUNT_SECURITY,
    component: Settings,
    template: DashboardTemplate,
    restricted: true
  },
  {
    path: ROUTES.DASHBOARD_ACCOUNT_NOTIFICATIONS,
    component: Settings,
    template: DashboardTemplate,
    restricted: true
  },
  {
    path: ROUTES.DASHBOARD_ACCOUNT_BILLING,
    component: Settings,
    template: DashboardTemplate,
    restricted: true
  },

  /** User */
  {
    path: ROUTES.DASHBOARD_USER_VERIFY_EMAIL,
    component: EmailVerificationPage,
    template: DashboardTemplate,
    restricted: true
  },

  /** Agency Admin */
  {
    path: ROUTES.DASHBOARD_ADMIN_USERS,
    component: UsersAdminPage,
    template: DashboardTemplate,
    restricted: true,
    routeRole: 'agencyAdmin'
  },
  {
    path: ROUTES.DASHBOARD_ADMIN_PROJECTS,
    component: ProjectsAdminPage,
    template: DashboardTemplate,
    restricted: true,
    routeRole: 'agencyAdmin'
  },

  /** Super Admin */
  {
    path: ROUTES.DASHBOARD_SUPER_ADMIN_USERS,
    component: UsersPage,
    template: DashboardTemplate,
    restricted: true,
    routeRole: 'superAdmin'
  },
  {
    path: ROUTES.DASHBOARD_USER_PROFILE,
    component: UserProfile,
    template: DashboardTemplate,
    restricted: true,
    routeRole: 'superAdmin'
  },
  {
    path: ROUTES.DASHBOARD_SUPER_ADMIN_PROJECTS,
    component: ProjectsPage,
    template: DashboardTemplate,
    restricted: true,
    routeRole: 'superAdmin'
  },
  {
    path: ROUTES.DASHBOARD_SUPER_ADMIN_PROJECT,
    component: ProjectDetailsPage,
    template: DashboardTemplate,
    restricted: true,
    routeRole: 'superAdmin'
  },
  {
    path: ROUTES.DASHBOARD_SUPER_ADMIN_AGENCIES,
    component: AgenciesPage,
    template: DashboardTemplate,
    restricted: true,
    routeRole: 'superAdmin'
  },
  {
    path: ROUTES.DASHBOARD_SUPER_ADMIN_AGENCY,
    component: AgencyDetailsPage,
    template: DashboardTemplate,
    restricted: true,
    routeRole: 'superAdmin'
  },

  /** No match */
  {
    component: NoMatch,
    template: SiteTemplate
  }
];
export default routes;
