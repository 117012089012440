import gql from 'graphql-tag';

export const SUPPORT_TICKETS_QUERY = gql`
  query($status: String) {
    support {
      tickets(status: $status) {
        id
        key
        fields {
          summary
          description
          created
          status {
            name
          }
        }
      }
    }
  }
`;
