import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { HeaderLogo } from './styles';
import * as ROUTES from 'constants/routes';

const Logo = ({ lightMode }) => (
  <Link to={ROUTES.HOME}>
    <HeaderLogo lightMode={lightMode} />
  </Link>
);

/** Type checking */
Logo.propTypes = {
  lightMode: PropTypes.bool
};

const mapStateToProps = ({ type: { lightMode } }) => ({ lightMode });

export default connect(mapStateToProps)(Logo);
