import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';

export const getProgress = ({ name }) => {
  const statusName = kebabCase(name);
  let progress = 0;

  switch (statusName) {
    case 'selected-for-development':
      progress = 5;
      break;

    case 'in-progress':
      progress = 20;
      break;

    case 'pr-approval-required':
      progress = 35;
      break;

    case 'under-review':
      progress = 50;
      break;

    case 'testing':
      progress = 60;
      break;

    case 'ready-to-release':
      progress = 80;
      break;

    case 'done':
      progress = 100;
      break;

    case 'resolved':
      progress = 100;
      break;

    case 'discarded':
      progress = 100;
      break;

    default:
      /** backlog / triage / estimate-required / awaiting-estimate */
      progress = 0;
  }

  return progress;
};

/** Type checking */
getProgress.propTypes = {
  name: PropTypes.string.isRequired
};
