import React from 'react';
import { connect } from 'react-redux';
import { supportTicketCount } from 'actions/support';
import SupportSidebar from 'views/Dashboard/Support/SupportSidebar';
import SupportTicketsCard from 'components/Cards/SupportTicketsCard';

const SupportTicketLists = ({ status, supportTicketCount, openTickets, closedTickets }) => (
  <>
    <SupportSidebar openCount={openTickets} closedCount={closedTickets} />
    <SupportTicketsCard status={status} border="false" search={true} ticketsToShow={20} />
  </>
);

const mapStateToProps = ({ support: { openTickets, closedTickets } }) => ({ openTickets, closedTickets });

export default connect(mapStateToProps, { supportTicketCount })(SupportTicketLists);
