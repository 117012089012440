import styled from 'styled-components';
import Gravatar from 'react-gravatar';
import { Avatar } from '@mui/material';

export const StyledAvatar = styled(Avatar)`
  background: ${(p) => p.theme.palette.common.white};
  border: 1px solid ${(p) => p.theme.palette.common.white};
`;

export const ImgAvatar = styled(Gravatar)`
  border-radius: 100%;
`;
