import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Step, StepLabel, Typography } from '@mui/material';
import { Check, Settings, GroupAdd, VideoLabel } from '@mui/icons-material';
import { StyledStepper, ButtonWrapper, StyledButton, StyledStepConnector, StyledIconWrapper } from './styles';
import { Form, Formik } from 'formik';
import FormHeader from 'components/FormHeader';
import ProjectDetails from './ProjectDetails';
import ProjectConnections from './ProjectConnections';
import Review from './Review';

const ColorlibStepIcon = ({ active, completed, icon }) => {
  const icons = {
    1: <Settings />,
    2: <GroupAdd />,
    3: <VideoLabel />
  };

  return (
    <StyledIconWrapper active={active} completed={completed}>
      {completed ? <Check /> : icons[String(icon)]}
    </StyledIconWrapper>
  );
};

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node
};

const AddProjectForm = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = ({ isExistingProject }) => {
    if (activeStep === 0 && isExistingProject === false) {
      setActiveStep(activeStep + 2);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = ({ isExistingProject }) => {
    if (activeStep === 2 && isExistingProject === false) {
      setActiveStep(activeStep - 2);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const handleReset = () => setActiveStep(0);

  // Super admin:
  //  - Are they a support client?
  //  - Jira project code

  const steps = ['Details', 'Connections', 'Summary'];

  const getStepContent = (step, values) => {
    switch (step) {
      case 0:
        return <ProjectDetails />;
      case 1:
        return <ProjectConnections />;
      case 2:
        return <Review values={values} />;
      default:
        throw new Error('Unknown step');
    }
  };

  const submitComplete = (values) => {
    console.log('values', values);
  };

  return (
    <>
      <FormHeader title="Let's get started" subtitle="Add New Project" lightState={true} />

      <StyledStepper alternativeLabel activeStep={activeStep} connector={<StyledStepConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </StyledStepper>

      <Formik
        initialValues={{
          projectName: '',
          projectType: '',
          projectSummary: ''
        }}
        // validationSchema={validationSchema}
        onSubmit={(values, formikBag) => submitComplete(values, formikBag)}
      >
        {({ values, isSubmitting }) => (
          <Form noValidate>
            {activeStep === steps.length ? (
              <>
                <Typography variant="h5" gutterBottom>
                  Thank you for your order.
                </Typography>
                <Typography variant="subtitle1">
                  Your order number is #2001539. We have emailed your order confirmation, and will send you an update
                  when your order has shipped.
                </Typography>
                <Button onClick={handleReset}>Reset</Button>
              </>
            ) : (
              <>
                <Box minHeight={200}>{getStepContent(activeStep, values)}</Box>
                <ButtonWrapper>
                  {activeStep !== 0 && (
                    <StyledButton variant="outlined" onClick={() => handleBack(values)}>
                      Back
                    </StyledButton>
                  )}
                  <StyledButton variant="contained" color="primary" onClick={() => handleNext(values)}>
                    {activeStep === steps.length - 1 ? 'Place order' : 'Next'}
                  </StyledButton>
                </ButtonWrapper>
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddProjectForm;
