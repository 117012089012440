import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import { Button, FormControl } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank, Visibility, VisibilityOff } from '@mui/icons-material';
import {
  SignInLink,
  IconButtonPosition,
  CenterButton,
  ErrorMessage,
  StyledErrorHelperText,
  TermLink,
  StyledLabel
} from './styles';
import { doCreateUserWithEmailAndPassword } from 'actions/auth/createUser';
import { validationSchema } from './validation';
import { StyledField } from 'components/Forms/LoginForm/styles';
import * as ROUTES from 'constants/routes';

const RegistrationForm = ({ signupError, doCreateUserWithEmailAndPassword, lightState }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword({ showPassword: !showPassword });
  };

  return (
    <Formik
      initialValues={{
        fname: '',
        lname: '',
        email: '',
        password1: '',
        password2: '',
        agree: false
      }}
      validationSchema={validationSchema}
      onSubmit={({ fname, lname, email, password1, agree }) => {
        doCreateUserWithEmailAndPassword(fname, lname, email, password1);
      }}
    >
      {({ errors, touched, values }) => (
        <Form noValidate>
          <StyledField
            component={TextField}
            name="fname"
            label="First name"
            autoComplete="fname"
            margin="normal"
            required
            fullWidth
            disabled={false}
          />
          <StyledField
            component={TextField}
            name="lname"
            label="Last name"
            autoComplete="lname"
            margin="normal"
            required
            fullWidth
            disabled={false}
          />
          <StyledField
            component={TextField}
            name="email"
            label="Email"
            autoComplete="email"
            margin="normal"
            required
            fullWidth
            disabled={false}
          />
          <FormControl fullWidth>
            <StyledField
              component={TextField}
              name="password1"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="password"
              margin="normal"
              required
              fullWidth
              disabled={false}
            />
            <IconButtonPosition
              color={lightState ? 'primary' : 'secondary'}
              aria-label="Toggle password visibility"
              onClick={handleClickShowPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButtonPosition>
          </FormControl>

          <StyledField
            component={TextField}
            name="password2"
            label="Confirm password"
            type="password"
            margin="normal"
            required
            fullWidth
            disabled={false}
            style={{ marginBottom: '30px' }}
          />

          <FormControl error={!!errors.agree}>
            <StyledField
              component={CheckboxWithLabel}
              icon={<CheckBoxOutlineBlank color="secondary" />}
              checkedIcon={<CheckBox color="secondary" />}
              Label={{
                label: (
                  <StyledLabel>
                    I accept the <TermLink to={ROUTES.TERMS}>terms of use</TermLink>
                  </StyledLabel>
                )
              }}
              type="checkbox"
              name="agree"
              required
            />
            <StyledErrorHelperText>{touched.agree && errors.agree ? errors.agree : ''}</StyledErrorHelperText>
          </FormControl>

          <ErrorMessage component="p">{signupError}</ErrorMessage>
          <CenterButton>
            <Button type="submit" size="large" variant="contained" color={lightState ? 'primary' : 'secondary'}>
              Sign Up
            </Button>
          </CenterButton>
          <CenterButton>
            <SignInLink component={Link} to={ROUTES.AUTH_SIGN_IN}>
              Already have an account? Sign In
            </SignInLink>
          </CenterButton>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = ({ errors: { signupError } }) => ({ signupError });

export default connect(mapStateToProps, { doCreateUserWithEmailAndPassword })(RegistrationForm);
