import React from 'react';
import { connect } from 'react-redux';
import FormHeader from 'components/FormHeader';
import RegistrationForm from 'components/Forms/RegistrationForm';

const SignUpPage = ({ lightMode }) => (
  <>
    <FormHeader
      forceColor="dark"
      lightState={lightMode}
      title="Register"
      subtitle="Please complete to create your account"
    />
    <RegistrationForm lightState={lightMode} />
  </>
);

const mapStateToProps = ({ type: { lightMode } }) => ({ lightMode });

export default connect(mapStateToProps)(SignUpPage);
