import React from 'react';
import styled from 'styled-components';
import { Drawer } from '@mui/material';

export const StyledDrawer = styled(({ ...other }) => <Drawer classes={{ paper: 'paper' }} {...other} />)`
  z-index: 4000;

  & .paper {
    padding: ${(p) => p.theme.spacing(4)};
    width: 300px;
  }
`;
