import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import * as ROUTES from 'constants/routes';

export const PrivateRoute = ({
  component: Component = null,
  render: Render = null,
  loggedIn,
  userRole,
  routeRole,
  ...rest
}) => {
  const redirector = ({ location }) => {
    let pathname = ROUTES.AUTH_SIGN_IN;

    if (loggedIn && routeRole === 'superAdmin' && userRole !== routeRole) {
      pathname = ROUTES.DASHBOARD;
    }

    return <Redirect to={{ pathname, state: { from: location } }} />;
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        redirector(props);

        return loggedIn ? Render ? Render(props) : Component ? <Component {...props} /> : null : redirector(props);
      }}
    />
  );
};
