import gql from 'graphql-tag';

export const SUPPORT_CLIENTS_QUERY = gql`
  query GetAllProjects($type: String) {
    getAllProjects(type: $type) {
      id
      title
      summary
      websiteUrl
      supportClient
      screenshot {
        secure_url
      }
    }
  }
`;
