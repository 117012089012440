import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { Box, Button, Grid } from '@mui/material';
import { ImgAvatar /*, StyledChip*/ } from './styles';
import { LIST_ALL_USERS_FROM_AGENCIES_QUERY } from './data';
import Table from 'components/Table';
import PageLoader from 'components/Loaders/PageLoader';
import StaticIntro from 'components/StaticIntro';
import * as ROUTES from 'constants/routes';

const createTablePagination = (number = false, value = 20) => {
  if (!number) return;

  // add correct logic for size
  // add logic for correct pagination options

  return {
    pageSize: number < value ? number : value,
    pageSizeOptions: [number, 5, 10, 20]
  };
};

const UsersPage = ({ agencies }) => (
  <>
    <Query query={LIST_ALL_USERS_FROM_AGENCIES_QUERY}>
      {({ loading, error, data }) => {
        if (loading) return <PageLoader />;
        if (error) return 'Currently unavailable';

        const { agencies } = data || {};

        return (
          <Grid container spacing={4}>
            <Grid item xs={12} md={2}></Grid>

            <Grid item xs={12} md={8}>
              <StaticIntro pretitle="Manager Team Members" title="Manage your companies team members">
                Invite or remove your team members
              </StaticIntro>

              {agencies &&
                agencies.map(({ name, users }, index) => (
                  <Box mb={5} key={index}>
                    <Table
                      title={name}
                      columns={[
                        { title: '', field: 'avatar', align: 'left', width: 40 },
                        { title: 'Name', field: 'name', align: 'left', width: 180 },
                        { title: 'Email', field: 'email', align: 'left' },
                        { title: '', field: 'userProfile', align: 'right' }
                      ]}
                      data={
                        users &&
                        users.map(({ uid, firstname, lastname, email }, index) => ({
                          avatar: <ImgAvatar email={email} size={38} default="mp" />,
                          name: `${firstname} ${lastname}`,
                          email,
                          userProfile: (
                            <Button
                              component={Link}
                              variant="outlined"
                              size="small"
                              to={`${ROUTES.DASHBOARD_USER_PROFILE_ROOT}/${uid}`}
                            >
                              View
                            </Button>
                          )
                        }))
                      }
                      options={{
                        sorting: true,
                        draggable: false,
                        header: false,
                        ...createTablePagination(users.length)
                      }}
                      style={{
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0
                      }}
                    />
                  </Box>
                ))}
            </Grid>

            <Grid item xs={12} md={2}></Grid>
          </Grid>
        );
      }}
    </Query>
  </>
);

const mapStateToProps = ({ auth: { agencies } }) => ({ agencies });

export default connect(mapStateToProps)(UsersPage);
