import React from 'react';
import PropTypes from 'prop-types';
// import { SearchWrapper, SearchIcon, SearchInputBase } from './styles';
import NotificationMenu from 'components/Menus/NotificationMenu';
import ProfileMenu from 'components/Menus/ProfileMenu';
import SettingsMenu from 'components/Menus/SettingsMenu';
// import GoToDashboardButton from 'components/Buttons/GoToDashboardButton';

const LoggedInMenu = ({ email, route, simple = false }) => (
  <>
    {!simple && (
      <>
        <NotificationMenu />
        <SettingsMenu />
      </>
    )}

    <ProfileMenu email={email} />
  </>
);

LoggedInMenu.propTypes = {
  email: PropTypes.string.isRequired,
  route: PropTypes.object.isRequired,
  simple: PropTypes.bool
};

export default LoggedInMenu;
