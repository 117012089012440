import styled from 'styled-components';
import { IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';

const thumbSize = 60;

export const AttachmentList = styled.ul`
  margin-bottom: ${(p) => p.theme.spacing(4)};
  padding: 0;
`;

export const AttachmentListItem = styled.li`
  display: inline-block;
  position: relative;
`;

export const Thumb = styled.div`
  background: ${(props) =>
    props.src ? 'url(' + props.src + ') no-repeat scroll center center ' + grey[100] : grey[100]};
  background-size: cover;
  border-radius: 6px;
  color: ${(p) => p.theme.palette.primary.main};
  cursor: pointer;
  height: ${thumbSize}px;
  margin-right: ${(p) => p.theme.spacing(2)};
  position: relative;
  overflow: hidden;
  width: ${thumbSize}px;

  .MuiSvgIcon-root {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`;

export const CloseButton = styled(IconButton)`
  background: ${(p) => p.theme.palette.primary.main} !important;
  color: ${(p) => p.theme.palette.secondary.main} !important;
  padding: 2px !important;
  position: absolute !important;
  top: 0;
  right: 0;
  transform: translate3d(-50%, -50%, 0);

  .MuiSvgIcon-root {
    font-size: 1rem;
  }
`;
