import { combineReducers } from 'redux';
import auth from 'reducers/authReducer';
import type from 'reducers/typeReducer';
import errors from 'reducers/errorReducer';
import menu from 'reducers/menuReducer';
import support from 'reducers/supportReducer';

const rootReducer = combineReducers({
  auth,
  errors,
  menu,
  support,
  type
});

export default rootReducer;
