import { SUPPORT_TICKET_COUNT } from 'actions/types';

/** Initial state */
const initState = {
  openTickets: 0,
  solvedTickets: 0,
  closedTickets: 0
};

const supportReducer = (state = initState, action) => {
  switch (action.type) {
    case SUPPORT_TICKET_COUNT:
      return {
        ...state,
        openTickets: action.data.openTickets ? action.data.openTickets : 0,
        solvedTickets: action.data.solvedTickets ? action.data.solvedTickets : 0,
        closedTickets: action.data.closedTickets ? action.data.closedTickets : 0
      };

    default:
      return state;
  }
};

export default supportReducer;
