import gql from 'graphql-tag';

export const ANNOUNCEMENTS_QUERY = gql`
  {
    announcements {
      announcements {
        title
        content
        date
        excerpt
      }
      total
    }
  }
`;
