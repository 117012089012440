import axios from 'axios';
import { print } from 'graphql';
import { authRef } from 'config/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { history } from 'helpers/History';
import { CREATE_USER_QUERY } from './data';
import { SIGNUP_SUCCESS, SIGNUP_ERROR } from 'actions/types';
import { friendlyFirebaseMessage } from 'helpers/firebaseMessages';
import * as ROUTES from 'constants/routes';

export const doCreateUserWithEmailAndPassword = (firstname, lastname, email, password) => (dispatch) => {
  /**
   * Once the user is created, it sets SIGNUP_SUCCESS to true.
   * Should probably do something with the returned data here.
   */
  return createUserWithEmailAndPassword(authRef, email, password)
    .then(async () => {
      const user = authRef.currentUser;

      /** Use the current user ID to set/get roles on backend */
      await axios
        .post(process.env.REACT_APP_BACKEND_URL, {
          query: print(CREATE_USER_QUERY),
          variables: {
            firstname,
            lastname,
            email: user.email,
            uid: user.uid
          }
        })
        .then(({ data: { data } }) => {
          const { email, role, verified, firstname, lastname, projectCount } = data.createUser;

          /** Once the backend end is updated call reducer */
          dispatch({
            type: SIGNUP_SUCCESS,
            data: {
              firstname,
              lastname,
              email,
              role,
              verified,
              projectCount
            }
          });
          history.push(ROUTES.DASHBOARD);
        })
        .catch(({ code }) => {
          dispatch({
            type: SIGNUP_ERROR,
            data: {
              signupError: friendlyFirebaseMessage(code)
            }
          });
        });
    })
    .catch(({ code }) => {
      dispatch({
        type: SIGNUP_ERROR,
        data: {
          signupError: friendlyFirebaseMessage(code)
        }
      });
    });
};
