import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import FormHeader from 'components/FormHeader';
import LoginForm from 'components/Forms/LoginForm';
import * as ROUTES from 'constants/routes';

const SignInPage = ({ loggedIn }) =>
  loggedIn ? (
    <Redirect to={ROUTES.DASHBOARD} />
  ) : (
    <>
      <FormHeader
        forceColor="dark"
        lightState={false}
        title="Login"
        subtitle="Welcome. Please login to your account."
      />
      <LoginForm lightState={false} />
    </>
  );

SignInPage.propTypes = {
  loggedIn: PropTypes.bool.isRequired
};

const mapStateToProps = ({ auth: { loggedIn } }) => ({ loggedIn });

export default connect(mapStateToProps)(SignInPage);
