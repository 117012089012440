import React from 'react';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { Root, Container, IntroColumn, CenterContent, Logo, PolicyLinks, StyledLink, IntroText } from './styles';
import * as ROUTES from 'constants/routes';

const AuthTemplate = ({ component: Component, route, title, description, lightState }) => {
  const policyLinks = [
    {
      title: 'Terms of use',
      link: ROUTES.TERMS
    },
    {
      title: 'Privacy Policy',
      link: ROUTES.PRIVACY
    }
  ];

  return (
    <Root>
      <Container container spacing={0} direction="row" alignItems="stretch">
        <IntroColumn item xs={6}>
          <Grid item xs={8}>
            <Link to={ROUTES.HOME}>
              <Logo />
            </Link>
            <IntroText component="h1" gutterBottom variant="h1">
              {title}
            </IntroText>
            <IntroText component="h2" variant="h4">
              {description}
            </IntroText>

            <PolicyLinks>
              {policyLinks.map(({ title, link }, index) => (
                <StyledLink key={index} to={link}>
                  {title}
                </StyledLink>
              ))}
            </PolicyLinks>
          </Grid>
        </IntroColumn>

        <CenterContent item xs={6}>
          <Grid item xs={6}>
            <Component route={route} lightState={lightState} />
          </Grid>
        </CenterContent>
      </Container>
    </Root>
  );
};

export default AuthTemplate;
