import axios from 'axios';
import { print } from 'graphql';
import { authRef } from 'config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { VERIFY_EMAIL_MUTATION } from './data';
import { VERIFY_USER_EMAIL } from 'actions/types';

export const verifyEmail = (token) => (dispatch) => {
  return new Promise((resolve, reject) => {
    return onAuthStateChanged(authRef, async (user) => {
      if (user) {
        await authRef.currentUser.getIdToken(true).then(async (idToken) => {
          await axios
            .post(
              process.env.REACT_APP_BACKEND_URL,
              {
                query: print(VERIFY_EMAIL_MUTATION),
                variables: { token }
              },
              {
                headers: {
                  authorization: idToken
                }
              }
            )
            .then(({ data: { data } }) => {
              const {
                verifyEmail: { emailVerified }
              } = data;

              dispatch({
                type: VERIFY_USER_EMAIL,
                data: {
                  verified: emailVerified
                }
              });

              resolve(emailVerified);
            })
            .catch((error) => console.log(error));
        });
      }
    });
  });
};
