import { MENU_TOGGLE } from 'actions/types';

/** Initial state */
const initState = {
  open: true,
  subMenuOpen: false
};

const menuReducer = (state = initState, action) => {
  switch (action.type) {
    case MENU_TOGGLE:
      return {
        ...state,
        open: action.data.open
      };

    default:
      return state;
  }
};

export default menuReducer;
