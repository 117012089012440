import gql from 'graphql-tag';

export const GET_USER_QUERY = gql`
  {
    user {
      firstname
      lastname
      email
      role
      verified
      projectCount
    }
  }
`;
