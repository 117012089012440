/** Session */
export const AUTH_SIGN_UP = '/register';
export const AUTH_SIGN_IN = '/login';
export const AUTH_PASSWORD_FORGET = '/account/password/reset';

/** Site */
export const HOME = '/';
export const TERMS = '/terms';
export const PRIVACY = '/privacy';

/** Dashboard */
export const DASHBOARD = '/dashboard';
export const DASHBOARD_PROJECTS = '/dashboard/projects';
export const DASHBOARD_PROJECT_ROOT = '/dashboard/project';
export const DASHBOARD_PROJECT_ADD = '/dashboard/project/add';
export const DASHBOARD_PROJECT = `${DASHBOARD_PROJECT_ROOT}/:id`;
export const DASHBOARD_REPORT = '/dashboard/report/:id';
export const DASHBOARD_PROJECT_TASK = `${DASHBOARD_PROJECT_ROOT}/task/:id`;

/** Dashboard - Account */
export const DASHBOARD_ACCOUNT = '/dashboard/account';
export const DASHBOARD_ACCOUNT_SECURITY = `${DASHBOARD_ACCOUNT}/security`;
export const DASHBOARD_ACCOUNT_NOTIFICATIONS = `${DASHBOARD_ACCOUNT}/notifications`;
export const DASHBOARD_ACCOUNT_BILLING = `${DASHBOARD_ACCOUNT}/billing`;

/** Dashboard - User */
export const DASHBOARD_USER_VERIFY_EMAIL = '/verify';
export const DASHBOARD_USER = '/user';
export const DASHBOARD_USER_PROFILE_ROOT = `${DASHBOARD_USER}/profile`;
export const DASHBOARD_USER_PROFILE = `${DASHBOARD_USER_PROFILE_ROOT}/:uid`;

/** Dashboard - Agency Admin */
export const DASHBOARD_ADMIN = '/dashboard/admin';

export const DASHBOARD_ADMIN_USERS = `${DASHBOARD_ADMIN}/users`;
export const DASHBOARD_ADMIN_USERS_ADMINS = `${DASHBOARD_ADMIN}/users/admins`;
export const DASHBOARD_ADMIN_USER_ROOT = `${DASHBOARD_ADMIN}/user`;

export const DASHBOARD_ADMIN_PROJECTS = `${DASHBOARD_ADMIN}/projects`;
export const DASHBOARD_ADMIN_PROJECT_ROOT = `${DASHBOARD_ADMIN}/project`;
export const DASHBOARD_ADMIN_PROJECT = `${DASHBOARD_ADMIN_PROJECT_ROOT}/:id`;

/** Dashboard - Super Admin */
export const DASHBOARD_SUPER_ADMIN = '/dashboard/superadmin';
export const DASHBOARD_SUPER_ADMIN_USERS = `${DASHBOARD_SUPER_ADMIN}/users`;
export const DASHBOARD_SUPER_ADMIN_USERS_ADMINS = `${DASHBOARD_SUPER_ADMIN}/users/admins`;

export const DASHBOARD_SUPER_ADMIN_PROJECTS = `${DASHBOARD_SUPER_ADMIN}/projects`;
export const DASHBOARD_SUPER_ADMIN_PROJECT_ROOT = `${DASHBOARD_SUPER_ADMIN}/project`;
export const DASHBOARD_SUPER_ADMIN_PROJECT = `${DASHBOARD_SUPER_ADMIN_PROJECT_ROOT}/:id`;

export const DASHBOARD_SUPER_ADMIN_AGENCIES = `${DASHBOARD_SUPER_ADMIN}/agencies`;
export const DASHBOARD_SUPER_ADMIN_AGENCY_ROOT = `${DASHBOARD_SUPER_ADMIN}/agency`;
export const DASHBOARD_SUPER_ADMIN_AGENCY = `${DASHBOARD_SUPER_ADMIN_AGENCY_ROOT}/:id`;

/** Dashboard - Support */
export const DASHBOARD_SUPPORT = '/dashboard/support';
export const DASHBOARD_SUPPORT_CLOSED = `${DASHBOARD_SUPPORT}/closed`;
export const DASHBOARD_SUPPORT_CREATE = `${DASHBOARD_SUPPORT}/create`;
export const DASHBOARD_SUPPORT_SOLVED = `${DASHBOARD_SUPPORT}/solved`;
export const DASHBOARD_SUPPORT_TICKET = `${DASHBOARD_SUPPORT}/ticket/:id`;
