import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Divider, MenuList, MenuItem } from '@mui/material';
import { ImgAvatar, StyledIconButtonSmall, LogoutWrapper, StyledPopover } from '../LoggedInMenu/styles';
import SignOut from 'views/Auth/SignOut';
import * as ROUTES from 'constants/routes';

const menu = [
  {
    route: ROUTES.DASHBOARD_ACCOUNT,
    text: 'Profile'
  },
  {
    route: ROUTES.DASHBOARD_ACCOUNT_SECURITY,
    text: 'Change Password'
  }
];

const ProfileMenu = ({ email, lightMode }) => {
  const [profileNav, setProfileNav] = useState(null);

  return (
    <>
      <StyledIconButtonSmall
        aria-owns={profileNav ? 'material-appbar' : undefined}
        aria-haspopup="true"
        onClick={(e) => setProfileNav(e.currentTarget)}
      >
        <ImgAvatar email={email} size={30} default="mp" />
      </StyledIconButtonSmall>

      <StyledPopover
        anchorEl={() => profileNav}
        open={Boolean(profileNav)}
        onClose={() => setProfileNav(null)}
        lightMode={lightMode}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuList>
          {menu.map(({ route, text }, index) => (
            <MenuItem key={index} component={Link} to={route}>
              {text}
            </MenuItem>
          ))}
        </MenuList>

        <Divider />

        <LogoutWrapper>
          <SignOut />
        </LogoutWrapper>
      </StyledPopover>
    </>
  );
};

ProfileMenu.propTypes = {
  email: PropTypes.string
};

const mapStateToProps = ({ type: { lightMode } }) => ({ lightMode });

export default connect(mapStateToProps)(ProfileMenu);
