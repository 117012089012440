import React from 'react';
import PropTypes from 'prop-types';
import { StyledCode } from './styles';

const CodeMessage = ({ code }) => {
  return <StyledCode>{code}</StyledCode>;
};

/** Type checking */
CodeMessage.propTypes = {
  code: PropTypes.string.isRequired
};

export default CodeMessage;
