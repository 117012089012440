import React, { useState } from 'react';
import { Query } from '@apollo/client/react/components';
import { ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Notifications, StarBorderOutlined } from '@mui/icons-material';
import { StyledList, StyledPaper, StyledPopover, StyledIconButton } from '../LoggedInMenu/styles';
import { StyledBadge, NotificationDescription } from './styles';
import { ANNOUNCEMENTS_QUERY } from './data';
import NotificationDialog from 'components/Dialogs/NotificationDialog';

const NotificationMenu = () => {
  const defaultModal = {
    title: null,
    date: null,
    content: null,
    open: false
  };

  const [notificationNav, setNotificationNav] = useState(false);
  const [modalOpen, setModalOpen] = useState(defaultModal);

  const handleNotificationMenuOpen = (event) => {
    setNotificationNav(event.currentTarget);
  };

  const handleNotificationMenuClose = () => {
    setNotificationNav(false);
  };

  const handleModalOpen = (title, date, content) => {
    setModalOpen({
      title,
      date,
      content,
      open: true
    });
  };

  const handleModalClose = () => {
    setModalOpen(defaultModal);
    setNotificationNav(false);
  };

  return (
    <Query query={ANNOUNCEMENTS_QUERY}>
      {({ loading, error, data }) => {
        if (loading || error) return null;

        const {
          announcements: { announcements, total }
        } = data || {};

        return (
          <>
            <StyledIconButton
              aria-label={`Show ${total} notifications`}
              aria-owns={notificationNav ? 'material-appbar' : undefined}
              aria-haspopup="true"
              onClick={handleNotificationMenuOpen}
            >
              <StyledBadge badgeContent={total}>
                <Notifications />
              </StyledBadge>
            </StyledIconButton>

            <StyledPopover
              anchorEl={() => notificationNav}
              open={Boolean(notificationNav)}
              onClose={handleNotificationMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              {total > 0 ? (
                <>
                  <StyledList>
                    {announcements.map(({ title, date, content, excerpt }, index) => (
                      <ListItem button key={index} onClick={() => handleModalOpen(title, date, content)}>
                        <ListItemIcon>
                          <StarBorderOutlined />
                        </ListItemIcon>
                        <ListItemText
                          primary={title}
                          secondary={<NotificationDescription component="span">{excerpt}</NotificationDescription>}
                        />
                      </ListItem>
                    ))}
                  </StyledList>
                  <NotificationDialog modalOpen={modalOpen} handleModalClose={handleModalClose} />
                </>
              ) : (
                <StyledPaper>
                  <NotificationDescription component="span">
                    We currently have no updates. Keep checking back, we are constantly pushing out updates.
                  </NotificationDescription>
                </StyledPaper>
              )}
            </StyledPopover>
          </>
        );
      }}
    </Query>
  );
};

export default NotificationMenu;
