import React from 'react';
import { ImgAvatar, StyledAvatar } from './styles';

const AvatarGenerator = (author) => {
  if (author.photo && author.photo.content_url) {
    return <StyledAvatar alt={author.name} src={author.photo.content_url} />;
  }

  if (author && author.email) {
    return <ImgAvatar email={author.email} size={38} default="mp" />;
  }

  return null;
};

export default AvatarGenerator;
