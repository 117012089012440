import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { DateTitle } from './styles';

const NotificationDialog = ({ modalOpen, handleModalClose }) => (
  <Dialog
    open={modalOpen.open}
    onClose={handleModalClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {modalOpen.title && <DialogTitle id="alert-dialog-title">{modalOpen.title}</DialogTitle>}
    <DialogContent>
      {modalOpen.date && (
        <DateTitle component="h6" variant="h6" gutterBottom>
          <Moment format="Do MMMM YYYY">{modalOpen.date}</Moment>
        </DateTitle>
      )}
      {modalOpen.content && <Typography dangerouslySetInnerHTML={{ __html: modalOpen.content }} />}
    </DialogContent>
    <DialogActions>
      <Button onClick={handleModalClose} variant="contained" color="primary">
        Thank you for the update
      </Button>
    </DialogActions>
  </Dialog>
);

NotificationDialog.propTypes = {
  modalOpen: PropTypes.object.isRequired,
  handleModalClose: PropTypes.func.isRequired
};

export default NotificationDialog;
