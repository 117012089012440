import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StickyHeader } from './styles';

const PopInHeader = ({ children, position = 150, open }) => {
  const [scrollY, setScrollY] = useState(0);

  const logit = () => setScrollY(window.pageYOffset);

  useEffect(() => {
    const watchScroll = () => window.addEventListener('scroll', logit);
    watchScroll();

    return () => window.removeEventListener('scroll', logit);
  });

  return (
    <StickyHeader isSticky={scrollY >= position ? true : false} open={open}>
      {children}
    </StickyHeader>
  );
};

/** Type checking */
PopInHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  position: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired
};

const mapStateToProps = ({ menu: { open } }) => ({ open });

export default connect(mapStateToProps)(PopInHeader);
