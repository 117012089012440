import styled from 'styled-components';
import { ReactComponent as LogoSvg } from '../../assets/img/logo.svg';

export const HeaderLogo = styled(({ lightMode, ...other }) => <LogoSvg {...other} />)`
  fill: ${(p) => (p.lightMode ? p.theme.palette.common.black : p.theme.palette.common.white)};
  height: 32px;
  margin: 10px 0;
  position: relative;
  width: auto;
`;
