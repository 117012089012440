import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

const open = ({ open }) => {
  if (!open) {
    return css`
      left: 100px !important;
      width: calc(100% - 110px) !important;
    `;
  }
};

export const Root = styled.div`
  /* background-color: ${(p) =>
    p.theme.palette.type === 'dark' ? p.theme.palette.grey[900] : p.theme.palette.common.white}; */
  display: flex;
`;

export const Main = styled(({ open, extraSidebar, ...other }) => <main {...other} />)`
  left: 250px;
  min-height: 100vh;
  position: relative;
  right: 10px;
  transition: ${(p) =>
    p.theme.transitions.create(['width', 'left'], {
      easing: p.theme.transitions.easing.sharp,
      duration: p.theme.transitions.duration.leavingScreen
    })} !important;
  width: calc(100% - 260px) !important;

  ${(p) => open(p)}
`;

export const MainInner = styled.div`
  min-height: 400px;
  padding: ${(p) => (p.extraSidebar ? 0 : p.theme.spacing(4))};
  position: relative;
`;

export const ContactButton = styled(Button)`
  margin: ${(p) => p.theme.spacing(2)} auto 0 !important;
`;

export const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${(p) => p.theme.spacing(0, 1)};
  ${(p) => p.theme.mixins.toolbar};
`;
