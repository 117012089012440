import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from 'reducers';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['errors']
};

const middlewares = [];

middlewares.push(thunkMiddleware);

const persistedReducer = persistReducer(persistConfig, rootReducer);

if (process.env.REACT_APP_NODE_ENV !== 'production') {
  const loggerMiddleware = createLogger();

  middlewares.push(loggerMiddleware);
}

const store = createStore(persistedReducer, applyMiddleware(...middlewares));

export default store;
export const persistor = persistStore(store);
