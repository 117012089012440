import styled from 'styled-components';
import { Card } from '@mui/material';
import { grey, amber } from '@mui/material/colors';

const backgroundColor = ({ theme, role, isPublic }) => {
  const { type, secondary, common } = theme.palette;

  if (isPublic) {
    if (type === 'light') {
      return role !== 'atlassian' ? grey[100] : secondary.main;
    } else {
      return role !== 'atlassian' ? '#1a1a1a' : common.black;
    }
  } else {
    return amber[500];
  }
};

export const CommentCard = styled(Card)`
  background: ${(p) => backgroundColor(p)} !important;
  padding: ${(p) => p.theme.spacing(2)} !important;
  margin-bottom: ${(p) => p.theme.spacing(4)};
  width: 100%;

  *,
  a {
    color: ${(p) => (p.isPublic ? 'inherit' : p.theme.palette.common.black)};
  }
`;
