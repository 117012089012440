import React from 'react';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { Button, Grid } from '@mui/material';
import { LIST_ALL_AGENCIES_QUERY } from './data';
import Table from 'components/Table';
import PageLoader from 'components/Loaders/PageLoader';
import StaticIntro from 'components/StaticIntro';
import * as ROUTES from 'constants/routes';

const UsersPage = () => {
  return (
    <>
      <StaticIntro pretitle="Management" title="Agencies" />

      <Query query={LIST_ALL_AGENCIES_QUERY}>
        {({ loading, error, data }) => {
          if (loading) return <PageLoader />;
          if (error) return 'Currently unavailable';

          const { agencies } = data || {};

          return (
            <Grid container>
              <Grid item xs={12}>
                <Table
                  title="Manage Users"
                  columns={[
                    { title: 'Name', field: 'name' },
                    { title: 'Actions', field: 'agencyProfile' }
                  ]}
                  data={agencies.map(({ id, name }, index) => {
                    return {
                      name,
                      agencyProfile: (
                        <Button
                          component={Link}
                          variant="outlined"
                          size="small"
                          to={`${ROUTES.DASHBOARD_SUPER_ADMIN_AGENCY_ROOT}/${id}`}
                        >
                          View
                        </Button>
                      )
                    };
                  })}
                  options={{
                    pageSize: agencies.length < 20 ? agencies.length : 20,
                    sorting: true,
                    draggable: false
                  }}
                />
              </Grid>
            </Grid>
          );
        }}
      </Query>
    </>
  );
};

export default UsersPage;
