import React from 'react';
import { Typography } from '@mui/material';

const NoMatch = () => (
  <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
    Oh no. It appears this page doesn't exist. We are very sorry.
  </Typography>
);

export default NoMatch;
