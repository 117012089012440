import React from 'react';
import { FormHeaderWrapper, FormTitle, FormSubtitle } from './styles';

const FormHeader = ({ title, subtitle, align, forceColor, lightState }) => (
  <FormHeaderWrapper align={align}>
    <FormTitle component="h1" variant="h5" forceColor={forceColor} lightState={lightState}>
      {title}
    </FormTitle>
    <FormSubtitle component="h2" variant="subtitle1">
      {subtitle}
    </FormSubtitle>
  </FormHeaderWrapper>
);

export default FormHeader;
