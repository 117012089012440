import gql from 'graphql-tag';

export const GET_PROJECT_QUERY = gql`
  query project($id: ID!) {
    project(id: $id) {
      title
      summary
      projectType
      projectCode
      websiteUrl
      monitor {
        id
        friendly_name
        url
        type
        logs {
          type
          type_name
          datetime
          duration
        }
      }
      ssl {
        valid
        message
      }
      screenshot {
        secure_url
      }
      reports {
        id
        startDate
        endDate
        reportPeriod
      }
    }
  }
`;
