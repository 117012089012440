import { connect } from 'react-redux';
import { supportTicketCount } from 'actions/support';
import SupportSidebar from 'views/Dashboard/Support/SupportSidebar';
import TaskPage from 'views/Dashboard/Tasks/TaskPage';

const SupportTicketPage = ({ route, extraSidebar, openTickets, closedTickets }) => {
  const id = route.match.params.id;

  return (
    <>
      <SupportSidebar openCount={openTickets} closedCount={closedTickets} />
      <TaskPage id={id} />
    </>
  );
};

const mapStateToProps = ({ support: { openTickets, closedTickets } }) => ({ openTickets, closedTickets });

export default connect(mapStateToProps, { supportTicketCount })(SupportTicketPage);
