import styled from 'styled-components';
import { Button, Chip, DialogTitle, Paper } from '@mui/material';
import { red, green } from '@mui/material/colors';

export const StyledPaper = styled(Paper)`
  padding: 0 !important;
`;

export const StyledPaperInner = styled.div`
  padding: ${(p) => p.theme.spacing(2)};
`;

export const IconButton = styled(Button)`
  svg {
    margin-right: 6px;
  }
`;

export const DeleteIconButton = styled(Button)`
  background-color: ${red[700]} !important;
  margin-right: ${(p) => p.theme.spacing(2)} !important;
  color: #ffffff !important;

  &.Mui-disabled {
    opacity: 0.5;
  }
`;

export const StyledChip = styled(Chip)`
  border-color: ${(props) => (props.verified === 'true' ? green[700] : red[700])} !important;
  color: ${(props) => (props.verified === 'true' ? green[700] : red[700])} !important;
`;

export const InlineCode = styled.code`
  background-color: ${(p) => p.theme.palette.grey[300]};
  border-radius: 4px;
  color: ${(p) => p.theme.palette.grey[700]};
  padding: 2px 6px;
  user-select: all;
`;

export const StyledDialogTitle = styled(DialogTitle)`
  text-align: center;
`;

export const CenterContent = styled.div`
  text-align: center;
`;
