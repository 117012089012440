import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { IntroContainer, PreTitle } from './styles';

const StaticIntro = ({ pretitle, title, children }) => (
  <IntroContainer>
    {pretitle && (
      <PreTitle component="h3" variant="h6">
        {pretitle}
      </PreTitle>
    )}

    {title && (
      <Typography component="h1" variant="h4" gutterBottom>
        <strong>{title}</strong>
      </Typography>
    )}

    {children && (
      <Typography component="p" gutterBottom>
        {children}
      </Typography>
    )}
  </IntroContainer>
);

StaticIntro.propTypes = {
  pretitle: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default StaticIntro;
