import axios from 'axios';
import { print } from 'graphql';
import { authRef } from 'config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { GET_USER_QUERY } from './data';
import { FETCH_USER, SIGNOUT_SUCCESS } from 'actions/types';

const fetchUser = () => (dispatch) => {
  return onAuthStateChanged(authRef, async (user) => {
    if (user) {
      await authRef.currentUser.getIdToken(true).then(async (idToken) => {
        /** Authenticate with the backend to get the user  */
        await axios
          .post(
            process.env.REACT_APP_BACKEND_URL,
            {
              query: print(GET_USER_QUERY)
            },
            {
              headers: {
                authorization: idToken
              }
            }
          )
          .then(({ data: { data } }) => {
            const { user } = data;

            if (user) {
              /** On successful login fetch the user role */
              dispatch({
                type: FETCH_USER,
                data: {
                  firstname: user.firstname,
                  lastname: user.lastname,
                  email: user.email,
                  role: user.role,
                  verified: user.verified,
                  projectCount: user.projectCount,
                  loggedIn: true,
                  agencies: user.agencies,
                  projects: user.projects
                }
              });
            } else {
              dispatch({
                type: SIGNOUT_SUCCESS
              });
            }
          });
      });
    } else {
      dispatch({
        type: SIGNOUT_SUCCESS
      });
    }
  });
};

export default fetchUser;
