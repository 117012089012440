import React from 'react';
import { connect } from 'react-redux';
import { Mutation } from '@apollo/client/react/components';
import { Grid } from '@mui/material';
import { StyledRadioButton } from './styles';
import { LIGHT_MODE_MUTATION } from './data';
import userLightMode from 'actions/auth/userLightMode';

const LightModeToggle = ({ userLightMode, lightMode }) => (
  <Mutation mutation={LIGHT_MODE_MUTATION}>
    {(updateUserLightMode, { loading, error, data }) => (
      <Grid container spacing={4}>
        <Grid item md={6}>
          <StyledRadioButton type="light" active={lightMode ? true : false} onClick={() => userLightMode(true)}>
            <div className="box box-one"></div>
            <div className="box box-two"></div>
            <div className="box box-three"></div>
            <div className="box box-four"></div>
          </StyledRadioButton>
        </Grid>

        <Grid item md={6}>
          <StyledRadioButton type="dark" active={!lightMode ? true : false} onClick={() => userLightMode(false)}>
            <div className="box box-one"></div>
            <div className="box box-two"></div>
            <div className="box box-three"></div>
            <div className="box box-four"></div>
          </StyledRadioButton>
        </Grid>
      </Grid>
    )}
  </Mutation>
);

const mapStateToProps = ({ type: { lightMode } }) => ({ lightMode });

export default connect(mapStateToProps, { userLightMode })(LightModeToggle);
