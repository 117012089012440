import React from 'react';
import { /*Divider,*/ List, ListItem, ListItemText, Typography } from '@mui/material';

const Review = ({ values }) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        Project summary
      </Typography>
      <List>
        <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
          <ListItemText primary="Project Name" secondary={values.projectName} />
        </ListItem>
        <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
          <ListItemText primary="Project Type" secondary={values.projectType} />
        </ListItem>
        <ListItem style={{ paddingLeft: 0, paddingRight: 0 }}>
          <ListItemText primary="Project Summary" secondary={values.projectSummary} />
        </ListItem>
      </List>

      {/* <Divider />

      <Typography variant="h6" gutterBottom>
        Connections
      </Typography> */}
    </>
  );
};

export default Review;
