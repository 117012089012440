import gql from 'graphql-tag';

export const PROJECT_QUERY = gql`
  query project($id: ID!, $startDate: String, $endDate: String, $showOpen: Boolean) {
    project(id: $id) {
      title
      summary
      projectType
      projectCode
      supportClient
      websiteUrl
      projectLead {
        email
        firstname
        lastname
      }
      clientDetails {
        email
        first_name
        importance
        last_name
        phone
      }
      # allowance {
      #   date
      #   hourly_rate
      #   hours_available
      # }
      ssl {
        valid
        validFrom
        validTo
        daysRemaining
      }
      contractStartDate
      tasks(startDate: $startDate, endDate: $endDate, showOpen: $showOpen) {
        id
        key
        fields {
          status {
            name
            statusCategory {
              name
            }
          }
          summary
          description
        }
      }
    }
  }
`;
