import styled from 'styled-components';
import { Card } from '@mui/material';

export const StyledCard = styled(Card)`
  margin-bottom: ${(p) => p.theme.spacing(4)};
`;

export const CardStat = styled.div`
  background: ${(props) => (props.positive ? '#4caf50' : '#e38933')};
  padding: ${(p) => p.theme.spacing(2)} ${(p) => p.theme.spacing(2)};
`;
