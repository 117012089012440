import React from 'react';
import { Grid } from '@mui/material';
import { connect } from 'react-redux';
import { supportTicketCount } from 'actions/support';
import SupportSidebar from 'views/Dashboard/Support/SupportSidebar';
import SupportTicketForm from 'components/Forms/SupportTicketForm';

const SupportCreate = ({ extraSidebar, openTickets, closedTickets }) => (
  <>
    <SupportSidebar openCount={openTickets} closedCount={closedTickets} />

    <Grid container>
      <Grid item xs={12} md={3}></Grid>
      <Grid item xs={12} md={6}>
        <SupportTicketForm />
      </Grid>
      <Grid item xs={12} md={3}></Grid>
    </Grid>
  </>
);

const mapStateToProps = ({ support: { openTickets, closedTickets } }) => ({ openTickets, closedTickets });

export default connect(mapStateToProps, { supportTicketCount })(SupportCreate);
