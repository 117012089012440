import styled from 'styled-components';
import { Button, Chip, Paper, Tabs } from '@mui/material';
import { red, green } from '@mui/material/colors';

export const StyledPaper = styled(Paper)`
  padding: 0 !important;
`;

export const StyledPaperInner = styled.div`
  padding: ${(p) => p.theme.spacing(2)};
`;

export const StyledTabs = styled(Tabs)`
  border-bottom: 1px solid ${(p) => p.theme.palette.grey[300]};
  margin: ${(p) => p.theme.spacing(5)} 0 ${(p) => p.theme.spacing(5)};
`;

export const SubmitButton = styled(Button)`
  margin-top: ${(p) => p.theme.spacing(2)} !important;
`;

export const IconButton = styled(Button)`
  svg {
    margin-right: 6px;
  }
`;

export const StyledChip = styled(Chip)`
  border-color: ${(p) => (p.verified === 'true' ? green[700] : red[700])} !important;
  color: ${(p) => (p.verified === 'true' ? green[700] : red[700])} !important;
`;
