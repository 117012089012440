import React from 'react';
// import { Link } from 'react-router-dom';
// import { Query } from '@apollo/client/react/components';
import { /*Button, Card, CardActionArea, CardContent,*/ Grid /*, Typography*/ } from '@mui/material';
// import { BrokenImage } from '@mui/icons-material';
// import { StyledCardMedia, NoImageWrapper } from './styles';
// import { GET_AGENCY_QUERY } from './data';
// import PageLoader from '../../../Loaders/PageLoader';
import StaticIntro from 'components/StaticIntro';
import AgencyDetailsTable from './AgencyDetailsTable';
// import * as ROUTES from '../../../../constants/routes';

const AgencyDetailsPage = ({ route }) => {
  const id = route.match.params.id;

  return (
    <>
      <StaticIntro pretitle="Management" title="Agency Overview" />

      {/* <Query query={GET_AGENCY_QUERY} variables={{ id }}>
        {({ loading, error, data }) => {
          if (loading) {
            return (
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={8}>
                  <PageLoader />
                </Grid>
              </Grid>
            );
          }

          if (error) {
            return (
              <>
                <Typography gutterBottom>
                  We have encountered an issue trying to fetch this project. Please return to the project list.
                </Typography>
                <Button component={Link} to={ROUTES.DASHBOARD_SUPER_ADMIN_PROJECTS} variant="contained" color="primary">
                  Go back to project list
                </Button>
              </>
            );
          }

          const { project } = data || {}; */}

      {/* return ( */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={6} lg={8}>
          <AgencyDetailsTable name={`Name for ${id} coming soon...`} />
        </Grid>
      </Grid>
      {/* );
        }}
      </Query>*/}
    </>
  );
};

export default AgencyDetailsPage;
