import { authRef } from 'config/firebase';
import { SIGNOUT_SUCCESS, SIGNOUT_ERROR } from 'actions/types';

export const doSignOut = () => (dispatch) => {
  return authRef
    .signOut()
    .then((result) => {
      dispatch({
        type: SIGNOUT_SUCCESS
      });
    })
    .catch((error) => {
      dispatch({
        type: SIGNOUT_ERROR
      });
    });
};
