import React from 'react';
import { connect } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import ProjectCard from 'components/Cards/ProjectCard';
import StaticIntro from 'components/StaticIntro';
import AddProjectCard from 'components/Cards/AddProjectCard';

const ProjectGrid = ({ projects, personal }) => (
  <>
    {!projects && (
      <Typography gutterBottom>
        <strong>You have no {personal ? 'personal ' : ''}projects.</strong>
      </Typography>
    )}

    <Grid container spacing={4} style={{ marginTop: '8px' }}>
      {typeof projects !== 'undefined' &&
        projects &&
        projects.length > 0 &&
        projects
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((project, index) => (
            <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
              <ProjectCard project={project} />
            </Grid>
          ))}

      {!projects && (
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <AddProjectCard card={true} />
        </Grid>
      )}
    </Grid>
  </>
);

const Projects = ({ agencies, projects }) => {
  /**
   * If a project is part of an agency and is also personal we don't want to display it twice.
   * So we can filter it out of personal.
   */
  const filteredProjects =
    projects &&
    projects.filter((project) =>
      []
        .concat(...agencies.map(({ projects }) => projects && projects.filter(({ id }) => id === project.id)))
        .filter((item) => item !== null).length
        ? false
        : project
    );

  return (
    <>
      <StaticIntro pretitle="Dashboard / Projects" title="All Your Projects" />

      {agencies &&
        agencies.length > 0 &&
        agencies
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ name, projects }, index) => (
            <Box mb={4} key={index}>
              <Typography variant="h5" component="h2">
                {name}
              </Typography>
              <Typography gutterBottom>
                Projects owned by {name}, or shared with {name} will appear below.
              </Typography>
              <ProjectGrid projects={projects} />
            </Box>
          ))}

      <Box>
        <Typography variant="h5" component="h2">
          Personal Projects
        </Typography>
        <Typography gutterBottom>
          Your personal projects are available below. If you you share a personal project with an agency, the project
          wil be listed under that agency.
        </Typography>
        <ProjectGrid projects={filteredProjects} personal={true} />
      </Box>

      <AddProjectCard />
    </>
  );
};

const mapStateToProps = ({ auth: { agencies, projects } }) => ({ agencies, projects });

export default connect(mapStateToProps)(Projects);
