import styled from 'styled-components';
import { SentimentDissatisfied } from '@mui/icons-material';

export const UnavailableWrapper = styled.div`
  left: 50%;
  opacity: 0.3;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 500px;
`;

export const StyledIcon = styled(SentimentDissatisfied)`
  display: block;
  font-size: 6rem;
  margin: 0 auto ${(p) => p.theme.spacing(2)};
`;
