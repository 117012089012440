import React from 'react';
import { Grid } from '@mui/material';
import AddProjectForm from 'components/Forms/AddProjectForm';

const ProjectAdd = () => (
  <Grid container spacing={4}>
    <Grid item xs={12} md={3}></Grid>
    <Grid item xs={12} md={6}>
      <AddProjectForm />
    </Grid>
    <Grid item xs={12} md={3}></Grid>
  </Grid>
);

export default ProjectAdd;
