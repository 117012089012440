import { SUPPORT_TICKET_COUNT } from './types';

export const supportTicketCount = (ticketCounts) => (dispatch) => {
  return dispatch({
    type: SUPPORT_TICKET_COUNT,
    data: {
      openTickets: ticketCounts.openTickets,
      solvedTickets: ticketCounts.solvedTickets,
      closedTickets: ticketCounts.closedTickets
    }
  });
};
