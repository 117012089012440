import Moment from 'react-moment';
import { connect } from 'react-redux';
import { Chip, CardContent, CardHeader, Typography } from '@mui/material';
import { CommentCard } from './styles';
import { StyledDescription } from '../styles';
import textToLink from 'helpers/content/textToLink';
import AvatarGenerator from 'helpers/content/AvatarGenerator';

const Comments = ({ comments, role }) => {
  if (!comments || (comments && comments.length <= 0)) {
    return <Typography component="p">There are currently no comments</Typography>;
  }

  return (
    <>
      <Typography component="h4" variant="h6" gutterBottom>
        Comments
      </Typography>
      {comments &&
        [...comments].reverse().map(({ body, created, author, jsdPublic }, index) => {
          if (jsdPublic || (!jsdPublic && role === 'superAdmin')) {
            return (
              <CommentCard key={index} role={author.accountType} isPublic={jsdPublic}>
                <CardHeader
                  avatar={AvatarGenerator(author)}
                  title={author.displayName}
                  subheader={<Moment format="Do MMMM YYYY | HH:mm">{created}</Moment>}
                />
                <CardContent>
                  {!jsdPublic && <Chip label="Private" variant="outlined" />}
                  <StyledDescription
                    dangerouslySetInnerHTML={{
                      __html: textToLink(body)
                    }}
                  />
                </CardContent>
              </CommentCard>
            );
          }
          return null;
        })}
    </>
  );
};

const mapStateToProps = ({ auth: { role } }) => ({ role });

export default connect(mapStateToProps)(Comments);
