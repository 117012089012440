import React from 'react';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { Button, Grid, Typography } from '@mui/material';
import { startCase, truncate } from 'lodash';
import { Link } from 'react-router-dom';
import { Query } from '@apollo/client/react/components';
import { StyledContainer, StyledChip, StyledLink, StyledPaper, StyledPaperInner } from './styles';
import { SUPPORT_TICKETS_QUERY } from './data';
import { supportTicketCount } from 'actions/support';
import Table from 'components/Table';
import LoadingSpinner from 'components/Loaders/LoadingSpinner';

const SupportTicketsCard = ({ status, border, search, ticketsToShow, supportTicketCount }) => {
  return (
    <Query query={SUPPORT_TICKETS_QUERY}>
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <StyledContainer container>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <StyledPaper border={border}>
                  <LoadingSpinner />
                </StyledPaper>
              </Grid>
            </StyledContainer>
          );
        }
        if (error) {
          return 'Currently unavailable';
        }

        const {
          support: { tickets }
        } = data || {};

        let ticketCount = 0;
        if (status === 'open') {
          ticketCount = tickets
            ? tickets.filter(
                (ticket) => ticket.fields.status.name !== 'Resolved' || ticket.fields.status.name === 'Done'
              ).length
            : 0;
        } else {
          ticketCount = tickets
            ? tickets.filter(
                (ticket) => ticket.fields.status.name === 'Resolved' || ticket.fields.status.name === 'Done'
              ).length
            : 0;
        }

        supportTicketCount({
          openTickets: tickets ? tickets.filter((ticket) => ticket.fields.status.name !== 'Resolved').length : 0,
          closedTickets: tickets ? tickets.filter((ticket) => ticket.fields.status.name === 'Resolved').length : 0
        });

        const statusMap = (ticket) => {
          if (status === 'open') {
            return ticket.fields.status.name !== 'Resolved' && ticket.fields.status.name !== 'Done' ? true : false;
          } else {
            return ticket.fields.status.name === 'Resolved' || ticket.fields.status.name === 'Done' ? true : false;
          }
        };

        if (ticketCount > 0) {
          return (
            <Table
              title={`${startCase(status)} Support Tickets`}
              columns={[
                { title: 'ID', field: 'id' },
                { title: 'Subject', field: 'subject' },
                { title: 'Description', field: 'description' },
                { title: 'Created', field: 'created_at' },
                { title: 'Status', field: 'status' },
                { field: 'view' }
              ]}
              data={tickets
                .filter((ticket) => statusMap(ticket))
                .map(({ id, key, fields }) => {
                  return {
                    id: key,
                    subject: <StyledLink to={`/dashboard/support/ticket/${key}`}>{fields.summary}</StyledLink>,
                    description: truncate(fields.description, { length: 60 }),
                    created_at: <Moment format="Do MMMM YYYY">{fields.created}</Moment>,
                    status: (
                      <StyledChip
                        label={startCase(status)}
                        size="small"
                        variant="outlined"
                        open={fields.status.name === 'Resolved' || fields.status.name === 'Done' ? true : false}
                      />
                    ),
                    view: (
                      <Button
                        color="primary"
                        variant="contained"
                        size="small"
                        component={Link}
                        to={`/dashboard/support/ticket/${key}`}
                      >
                        View Ticket
                      </Button>
                    )
                  };
                })}
              options={{
                pageSize:
                  tickets.filter((ticket) => ticket.status === status).length > 5 &&
                  tickets.filter((ticket) => ticket.status === status).length < ticketsToShow
                    ? tickets.filter((ticket) => ticket.status === status).length
                    : tickets.filter((ticket) => ticket.status === status).length === 0
                    ? 5
                    : ticketsToShow,
                sorting: true,
                draggable: false,
                search
              }}
              style={{
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0
              }}
              border={border}
              lastColumnWide={true}
            />
          );
        }

        return (
          <StyledPaper>
            <StyledPaperInner>
              <Typography variant="body" component="p">
                You have no {status} tickets at the moment
              </Typography>
            </StyledPaperInner>
          </StyledPaper>
        );
      }}
    </Query>
  );
};

const mapStateToProps = ({ support: { openTickets, solvedTickets, closedTickets } }) => ({
  openTickets,
  solvedTickets,
  closedTickets
});

export default connect(mapStateToProps, { supportTicketCount })(SupportTicketsCard);
