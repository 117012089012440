import gql from 'graphql-tag';

export const DASHBOARD_FOOTER_QUERY = gql`
  query {
    content {
      dashboard {
        footer
      }
    }
  }
`;
