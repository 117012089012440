import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Toolbar } from '@mui/material';
import { StyledAppBar, AppBarSpacer, Grow } from './styles';
import LoggedInMenu from 'components/Menus/LoggedInMenu';
import LoggedOutMenu from 'components/Menus/LoggedOutMenu';

const Header = ({ loggedIn, email, open, route, intro, lightMode }) => {
  return (
    <>
      <StyledAppBar lightMode={lightMode} open={open}>
        <Toolbar>
          {intro}
          <Grow />
          {loggedIn ? <LoggedInMenu route={route} email={email} /> : <LoggedOutMenu />}
        </Toolbar>
      </StyledAppBar>

      <AppBarSpacer />
    </>
  );
};

/** Type checking */
Header.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  email: PropTypes.string,
  open: PropTypes.bool,
  route: PropTypes.object.isRequired
};

/** Takes the auth state from redux and stores it in this.props */
const mapStateToProps = ({ auth: { loggedIn, email }, type: { lightMode }, menu: { open } }) => ({
  loggedIn,
  open,
  email,
  lightMode
});

export default connect(mapStateToProps)(Header);
