import styled from 'styled-components';
import { grey, yellow } from '@mui/material/colors';

export const LoaderWrapper = styled.div`
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 500px;

  svg {
    rect {
      fill: ${yellow[600]};
    }
  }
`;

export const SongText = styled.span`
  color: ${grey[600]};
  display: inline-block;
  font-size: 14px;
  margin-top: ${(p) => p.theme.spacing(1)};
  max-width: 280px;
`;
