import gql from 'graphql-tag';

export const GET_PROJECT_TYPE = gql`
  query settings {
    settings {
      project {
        projectType {
          label
          value
        }
      }
    }
  }
`;
