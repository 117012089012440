import React, { Fragment } from 'react';
import { NavLink, matchPath } from 'react-router-dom';
import { connect } from 'react-redux';
import { List, ListItemIcon, Tooltip, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { StyledDrawer, StyledList, StyledListItem, StyledListItemText } from './styles';
import { navigation, agencyNavigation, superNavigation } from './data';
import MenuToggleButton from 'components/Buttons/MenuToggle';
import AdminMenu from 'components/Menus/AdminMenu';
import Logo from 'components/Logo';

const DashboardMenu = ({
  handleDrawerClose,
  handleDrawerOpen,
  open,
  role,
  settingsSubMenuToggle,
  settingsSubMenuOpen,
  location
}) => {
  const pathChecker = (match, location, route) => {
    let subRoute = route.split('/');
    let subPathname = location.pathname.split('/');

    const matchProfile = matchPath(subPathname[2], {
      path: subRoute[2],
      exact: true
    });

    if (matchProfile) {
      return true;
    }

    if (!matchProfile) {
      const reMatchProfile = matchPath(location.pathname, {
        path: route,
        exact: true
      });

      if (reMatchProfile) {
        return true;
      }
    }

    return false;
  };

  return (
    <StyledDrawer open={open} variant="permanent">
      <Logo />

      <StyledList component="nav" aria-label="Dashboard navigation">
        {navigation.map(({ route, icon, text, label, children }, index) => (
          <Fragment key={index}>
            {open || (!open && !children) ? (
              <Tooltip title={!open ? label : ''} placement="right">
                <StyledListItem
                  button
                  exact={route ? true : ''}
                  component={route ? NavLink : null}
                  isActive={(match, location) => pathChecker(match, location, route)}
                  to={route}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <StyledListItemText primary={text} />
                  {children && children.length > 0 ? settingsSubMenuOpen ? <ExpandLess /> : <ExpandMore /> : null}
                </StyledListItem>
              </Tooltip>
            ) : null}

            {children && (
              <Collapse in={settingsSubMenuOpen || !open} timeout="auto" unmountOnExit>
                <List component="nav" disablePadding>
                  {children.map(({ route, icon, text, type, children, label }, index) => (
                    <Tooltip key={index} title={!open ? label : ''} placement="right">
                      <StyledListItem
                        key={index}
                        button
                        exact
                        component={NavLink}
                        isActive={(match, location) => pathChecker(match, location, route)}
                        to={route}
                      >
                        <ListItemIcon>{icon}</ListItemIcon>
                        <StyledListItemText primary={text} />
                      </StyledListItem>
                    </Tooltip>
                  ))}
                </List>
              </Collapse>
            )}
          </Fragment>
        ))}

        {(role === 'superAdmin' || role === 'agencyAdmin') && <AdminMenu navigation={agencyNavigation} />}

        {role === 'superAdmin' && <AdminMenu navigation={superNavigation} />}

        <MenuToggleButton open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />
      </StyledList>
    </StyledDrawer>
  );
};

const mapStateToProps = ({ auth: { role }, menu: { open } }) => ({ role, open });

export default connect(mapStateToProps)(DashboardMenu);
