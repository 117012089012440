import React from 'react';
import { /*Checkbox, FormControlLabel, TextField, */ Grid, Typography } from '@mui/material';

const ProjectConnections = (props) => {
  // If existing project:
  //  - Connect Analytics
  //  - Domain

  return (
    <>
      <Typography variant="h6" gutterBottom>
        Add existing connections
      </Typography>
      <Grid container spacing={3}>
        {/* <Grid item xs={12} sm={6}>
        <TextField
          onChange={props.formHandler}
          required
          id="analyticsViewID"
          name="analyticsViewID"
          label="Analytics ViewID"
          value={props.projectDetails.analyticsViewID.value}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          onChange={props.formHandler}
          required
          id="jiraProjectCode"
          name="jiraProjectCode"
          label="Project Code"
          value={props.projectDetails.analyticsViewID.value}
          fullWidth
        />
      </Grid> */}
      </Grid>
    </>
  );
};

export default ProjectConnections;
