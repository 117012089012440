import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { StyledActionButtons, StyledLinearProgress } from './styles';
import { getProgress } from 'helpers/getProgress';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Table from 'components/Table';
import LoadingSpinner from 'components/Loaders/LoadingSpinner';
import * as ROUTES from 'constants/routes';

const ProjectTasksCard = ({
  role,
  id,
  data,
  error,
  loading,
  setLoadingTable,
  setShowOpen,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  months,
  setMonths,
  selectedMonth,
  setSelectedMonth
}) => {
  const [fineTuning, setFineTuning] = useState(false);

  const { project } = data || {};
  let { tasks } = project || {};
  tasks = tasks || [];

  const handleMonthChange = (month) => {
    setLoadingTable(true);
    setSelectedMonth(month);

    // if month is current month set showopen
    if (moment(month, 'MMMM YYYY').isSame(moment(), 'month')) {
      setShowOpen(true);
    } else {
      setShowOpen(false);
    }

    setStartDate(moment(month, 'MMMM YYYY').startOf('month').format('YYYY/MM/DD'));
    setEndDate(moment(month, 'MMMM YYYY').endOf('month').format('YYYY/MM/DD'));
  };

  useEffect(() => {
    if (!loading && project) {
      const contractStartDate = project.contractStartDate
        ? moment.utc(project.contractStartDate, 'DD/MM/YYYY')
        : moment.utc().startOf('month');
      const formattedContractStartDate = moment(contractStartDate).format('MM-DD-YYYY');
      const start = moment().startOf('month');
      const end = moment(formattedContractStartDate, 'MM-DD-YYYY');

      let newMonths = [];
      let current = start;
      while (current.format('MMMM YYYY') !== end.format('MMMM YYYY')) {
        newMonths.push(current.format('MMMM YYYY'));
        current = current.subtract(1, 'month');
      }
      // need to add current one last time because the loop will have ended when it is equal to the end date, and will not have added it
      newMonths.push(current.format('MMMM YYYY'));
      setMonths(newMonths);

      // set selected month to current month only if it hasn't been set yet
      if (!selectedMonth) {
        setSelectedMonth(moment().format('MMMM YYYY'));
      }
    }
  }, [project, loading, setSelectedMonth, setMonths, selectedMonth]);

  let taskSummary = '';
  if (moment(endDate, 'YYYY/MM/DD').isBefore(moment(), 'month')) {
    taskSummary = `This list shows tasks that were completed between ${moment(startDate, 'YYYY/MM/DD').format(
      'DD/MM/YYYY'
    )} and ${moment(endDate, 'YYYY/MM/DD').format('DD/MM/YYYY')}`;
  } else {
    taskSummary = 'This list shows tasks that were completed or still open this month';
  }

  return (
    <>
      <Box sx={{ mb: 3 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={fineTuning}
              onChange={(event) => setFineTuning(event.target.checked)}
              name="fineTuning"
              color="primary"
            />
          }
          label="Fine tuning"
          style={{ position: 'relative', top: '6px' }}
        />

        {fineTuning ? (
          <>
            <span style={{ marginRight: '16px' }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Start Date"
                  value={moment.utc(startDate, 'YYYY/MM/DD')}
                  onChange={(newValue) => setStartDate(moment.utc(newValue).format('YYYY/MM/DD'))}
                />
              </LocalizationProvider>
            </span>

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="End Date"
                value={moment.utc(endDate, 'YYYY/MM/DD')}
                onChange={(newValue) => setEndDate(moment.utc(newValue).format('YYYY/MM/DD'))}
              />
            </LocalizationProvider>
          </>
        ) : (
          <FormControl>
            <InputLabel id="select-month-label">Select Month</InputLabel>
            <Select
              labelId="select-month-label"
              id="select-month"
              value={selectedMonth}
              onChange={(e) => handleMonthChange(e.target.value)}
              label="Select Month"
              style={{ minWidth: '180px' }}
            >
              {months.map((month, index) => (
                <MenuItem key={index} value={month}>
                  {month}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      <Typography component="h2" variant="h2">
        <strong>{selectedMonth}</strong>
      </Typography>

      {taskSummary && (
        <Typography component="p" sx={{ mb: 4 }}>
          {taskSummary}
        </Typography>
      )}

      <Table
        columns={
          loading
            ? [{ title: '', field: 'message', cellStyle: { textAlign: 'center' } }]
            : [
                { title: 'ID', field: 'id' },
                { title: 'Summary', field: 'summary' },
                {
                  title: 'Status',
                  field: 'status',
                  lookup: { 'To Do': 'To Do', 'In Progress': 'In Progress', Done: 'Done' }
                },
                {
                  title: 'Progress',
                  field: 'progress',
                  filtering: false,
                  customSort: (a, b) => b.progress.props.value - a.progress.props.value
                },
                { field: 'openComment', filtering: false }
              ]
        }
        data={
          loading
            ? [{ message: <LoadingSpinner /> }]
            : error
            ? [{ message: 'An error has occurred. Please try again later.' }]
            : tasks &&
              tasks.map((task) => {
                const { key, fields } = task;

                return {
                  id: key,
                  summary: fields.summary,
                  status: fields.status.statusCategory.name,
                  progress: <StyledLinearProgress variant="determinate" value={getProgress(fields.status)} />,
                  openComment: (
                    <StyledActionButtons>
                      <Button
                        variant="outlined"
                        size="small"
                        component={Link}
                        to={`${ROUTES.DASHBOARD_PROJECT_ROOT}/task/${key}`}
                      >
                        View task
                      </Button>

                      {role === 'superAdmin' && (
                        <Button
                          component="a"
                          href={`https://andanotherday.atlassian.net/browse/${key}`}
                          target="_blank"
                          variant="outlined"
                          size="small"
                          style={{ marginLeft: '8px' }}
                          endIcon={<OpenInNew />}
                        >
                          Open in Jira
                        </Button>
                      )}
                    </StyledActionButtons>
                  )
                };
              })
        }
        localization={{
          body: {
            emptyDataSourceMessage: 'There are currently no tasks for this project'
          }
        }}
        options={{
          actionsColumnIndex: -1,
          pageSize: tasks && tasks.length > 0 ? (tasks.length < 10 ? tasks.length : 10) : 5,
          sorting: loading || error ? false : true,
          draggable: false,
          filtering: loading || error ? false : true,
          search: false,
          showTitle: false,
          toolbar: false
        }}
      />
    </>
  );
};

/** Type checking */
ProjectTasksCard.propTypes = {
  id: PropTypes.string,
  role: PropTypes.string
};

const mapStateToProps = ({ auth: { role } }) => ({ role });

export default connect(mapStateToProps)(ProjectTasksCard);
