import { LOGIN_ERROR, SIGNUP_ERROR } from 'actions/types';

/** Initial state */
const initState = {
  loginError: null,
  signupError: null
};

const errorReducer = (state = initState, action) => {
  switch (action.type) {
    case LOGIN_ERROR:
      return {
        ...state,
        loginError: action.data.loginError
      };

    case SIGNUP_ERROR:
      return {
        ...state,
        signupError: action.data.signupError
      };

    default:
      return state;
  }
};

export default errorReducer;
