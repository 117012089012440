import { createGlobalStyle } from 'styled-components';
import SaggacityRegularWoff2 from 'assets/fonts/saggacity-regular.woff2';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Saggacity';
    src: url(${SaggacityRegularWoff2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  a {
    color: inherit;
  }

  /** Bug fix for hidden inputs appearing next to selects */
  .MuiSelect-root {
    + input {
      display: none;
    }
  }
`;

export default GlobalStyles;
