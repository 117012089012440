import gql from 'graphql-tag';

export const LIST_ALL_USERS_FROM_AGENCIES_QUERY = gql`
  {
    agencies {
      name
      users {
        uid
        firstname
        lastname
        email
        emailVerified
      }
    }
  }
`;
