import React from 'react';
import { Query } from '@apollo/client/react/components';
import { Link } from 'react-router-dom';
import { Box, Card, CardContent, CardMedia, Grid, Paper, Typography, Button } from '@mui/material';
import { StyledContainer } from './styles';
import { SUPPORT_CLIENTS_QUERY } from './data';
import LoadingSpinner from 'components/Loaders/LoadingSpinner';
import ContactCard from 'components/Cards/ContactCard';
import * as ROUTES from 'constants/routes';

const SupportClients = () => {
  return (
    <Query query={SUPPORT_CLIENTS_QUERY} variables={{ type: 'support' }}>
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <StyledContainer container>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Paper>
                  <LoadingSpinner />
                </Paper>
              </Grid>
            </StyledContainer>
          );
        }

        if (error) return 'Currently unavailable';

        const { getAllProjects } = data || {};

        if (typeof getAllProjects !== 'undefined' && getAllProjects !== null && getAllProjects.length > 0) {
          return (
            <>
              <Typography variant="h6" component="h2" sx={{ mb: 1 }}>
                Support Clients
              </Typography>

              <Grid container spacing={2}>
                {getAllProjects.map(({ id, title, summary, websiteUrl, screenshot }, index) => (
                  <Grid item xs={6}>
                    <Card
                      sx={{ display: 'flex' }}
                      style={{ textDecoration: 'none' }}
                      component={Link}
                      to={`${ROUTES.DASHBOARD_PROJECT_ROOT}/${id}`}
                      key={index}
                    >
                      {screenshot?.secure_url && (
                        <CardMedia component="img" sx={{ width: 250 }} image={screenshot.secure_url} alt={title} />
                      )}
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                          <Typography component="h6" variant="h6">
                            {title}
                          </Typography>
                          <Typography variant="body2" color="textSecondary" component="p" sx={{ mb: 2 }}>
                            <a href={websiteUrl} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                              {websiteUrl}
                            </a>
                          </Typography>
                          <Typography variant="body" component="p" sx={{ mb: 2 }}>
                            {summary}
                          </Typography>
                          <Typography variant="subtitle1" color="text.secondary" component="div">
                            <Button component={Link} variant="outlined" to={`${ROUTES.DASHBOARD_PROJECT_ROOT}/${id}`}>
                              View
                            </Button>
                          </Typography>
                        </CardContent>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </>
          );
        }

        return <ContactCard />;
      }}
    </Query>
  );
};

/** Type checking */
// SupportClients.propTypes = {
// };

export default SupportClients;
