import React, { useEffect } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { CallMade } from '@mui/icons-material';
import { ExternalLink } from './styles';
import PopInHeader from 'components/PopInHeader';

const ProjectIntro = ({ project, setIntro }) => {
  useEffect(() => {
    const populateIntro = (wipe) => {
      if (wipe) {
        setIntro(null);
      } else {
        setIntro(
          <PopInHeader position={150}>
            <Typography component="p" variant="body1" color="primary">
              {project.title}
              {project.projectType === 'website' ? (
                <ExternalLink href={project.websiteUrl} target="_blank" rel="noopener noreferrer">
                  <Button endIcon={<CallMade />} variant="outlined" color="primary" size="small">
                    Visit site
                  </Button>
                </ExternalLink>
              ) : null}
            </Typography>
          </PopInHeader>
        );
      }
    };

    populateIntro();

    return function cleanup() {
      populateIntro(true);
    };
  }, [project, setIntro]);

  return (
    <Grid container spacing={4} sx={{ mb: 4 }}>
      <Grid item xs={12} md={8}>
        <Typography component="h1" gutterBottom variant="h4">
          {project.title}
          {project.projectType === 'website' ? (
            <ExternalLink href={project.websiteUrl} target="_blank" rel="noopener noreferrer">
              <Button endIcon={<CallMade />} variant="outlined" color="primary">
                Visit site
              </Button>
            </ExternalLink>
          ) : null}
        </Typography>
        <Typography component="p" gutterBottom>
          {project.summary}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ProjectIntro;
