import gql from 'graphql-tag';

export const CREATE_USER_QUERY = gql`
  mutation createUser($firstname: String!, $lastname: String!, $email: String!, $uid: ID!) {
    createUser(firstname: $firstname, lastname: $lastname, email: $email, uid: $uid) {
      firstname
      lastname
      email
      role
      verified
    }
  }
`;
