import { MENU_TOGGLE } from './types';

export const menuToggle = (open) => (dispatch) => {
  return dispatch({
    type: MENU_TOGGLE,
    data: {
      open
    }
  });
};
