import gql from 'graphql-tag';

export const PROJECTS_QUERY = gql`
  {
    projects {
      id
      title
      websiteUrl
      screenshot {
        secure_url
      }
    }
  }
`;
