import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Query } from '@apollo/client/react/components';
import { Link } from 'react-router-dom';
import { Divider, List, ListItemText, ListItemIcon, Grid, Paper, Avatar, Typography } from '@mui/material';
import { StyledContainer, StyledPaper, StyledPaperInner, StyledListItem } from './styles';
import { PROJECTS_QUERY } from './data';
import LoadingSpinner from 'components/Loaders/LoadingSpinner';
import ContactCard from 'components/Cards/ContactCard';
import * as ROUTES from 'constants/routes';

const ProjectsListCard = ({ projectCount }) => {
  return (
    <Query query={PROJECTS_QUERY}>
      {({ loading, error, data }) => {
        if (loading) {
          return (
            <StyledContainer container>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Paper>
                  <LoadingSpinner />
                </Paper>
              </Grid>
            </StyledContainer>
          );
        }

        if (error)
          return (
            <StyledPaper>
              <StyledPaperInner>
                <Typography variant="body1" component="p">
                  Currently unavailable
                </Typography>
              </StyledPaperInner>
            </StyledPaper>
          );

        const { projects } = data || {};

        if (typeof projects !== 'undefined' && projects !== null && projects.length > 0) {
          return (
            <StyledPaper>
              <StyledPaperInner>
                <Typography variant="h6" component="h2">
                  Your Projects
                </Typography>
              </StyledPaperInner>
              <Divider />
              <List>
                {projects.map(({ id, title, websiteUrl, screenshot }, index) => (
                  <StyledListItem button component={Link} to={`${ROUTES.DASHBOARD_PROJECT_ROOT}/${id}`} key={index}>
                    <ListItemIcon>
                      {screenshot?.url ? (
                        <Avatar alt={title}>{title.substring(0, 1)}</Avatar>
                      ) : (
                        <Avatar alt={title} src={screenshot.secure_url} />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={title} secondary={websiteUrl ? websiteUrl : null} />
                  </StyledListItem>
                ))}
              </List>
            </StyledPaper>
          );
        }

        return <ContactCard />;
      }}
    </Query>
  );
};

/** Type checking */
ProjectsListCard.propTypes = {
  projectCount: PropTypes.number
};

const mapStateToProps = ({ auth: { projectCount } }) => ({ projectCount });

export default connect(mapStateToProps)(ProjectsListCard);
