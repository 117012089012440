import React from 'react';
import {
  /*Box, Select, InputLabel, FormControlLabel, Checkbox*/
  Grid,
  MenuItem,
  Typography
} from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { Query } from '@apollo/client/react/components';
import { GET_PROJECT_TYPE } from './data';
// import PageLoader from 'components/Loaders/PageLoader';

const ProjectDetails = ({ projectDetails, formHandler }) => {
  // Details:
  //  - New project or existing project?
  //  - Project name
  //  - Summary
  //  - Type

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          About the project
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <Field
          component={TextField}
          variant="outlined"
          required
          id="projectName"
          name="projectName"
          label="Project Name"
          margin="normal"
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Query query={GET_PROJECT_TYPE}>
          {({ loading, error, data }) => {
            if (loading) {
              return (
                <Field
                  component={TextField}
                  variant="outlined"
                  name="projectType"
                  label="Loading project types..."
                  select
                  margin="normal"
                  fullWidth
                  disabled={true}
                >
                  <MenuItem value="">Loading project types...</MenuItem>
                </Field>
              );
            }

            if (error) return null;

            const { settings } = data || {};

            if (typeof settings.project === 'undefined' && typeof settings.project.projectType === 'undefined') {
              return null;
            }

            return (
              <Field
                component={TextField}
                variant="outlined"
                name="projectType"
                label="Select Project Type"
                select
                margin="normal"
                required
                fullWidth
              >
                <MenuItem value="">Select project type</MenuItem>
                {settings.project &&
                  settings.project.projectType.map(({ label, value }, index) => (
                    <MenuItem key={index} value={value}>
                      {label}
                    </MenuItem>
                  ))}
              </Field>
            );
          }}
        </Query>
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          required
          multiline
          variant="outlined"
          id="projectSummary"
          rows={6}
          name="projectSummary"
          margin="normal"
          label="Summary"
          fullWidth
        />
      </Grid>
      {/* <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              color="secondary"
              name="isExistingProject"
              checked={projectDetails.isExistingProject}
              onChange={formHandler}
              label="Pre-exisitng project"
            />
          }
          label="Is this project already live &amp; existing?"
        />
      </Grid> */}
    </Grid>
  );
};

export default ProjectDetails;
