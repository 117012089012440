import gql from 'graphql-tag';

export const GET_USER_QUERY = gql`
  query getUser($uid: ID!) {
    getUser(uid: $uid) {
      uid
      firstname
      lastname
      email
      emailVerified
      metadata {
        lastSignInTime
        creationTime
      }
    }
  }
`;
