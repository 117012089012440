import React from 'react';
import { connect } from 'react-redux';
import { Chart, LinearScale } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Box, Paper } from '@mui/material';
import { yellow } from '@mui/material/colors';

const ProjectMonitoringCard = ({ monitors, lightMode }) => {
  if (monitors && monitors.length > 0) {
    const logs = monitors[0].logs.slice(0).reverse();

    Chart.register(LinearScale);

    const chartData = {
      labels: logs.map(({ datetime }) => datetime),
      datasets: [
        {
          label: `${monitors[0].friendly_name} Logs`,
          fill: false,
          lineTension: 0.1,
          borderColor: yellow[600],
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: logs.map(({ type }) => type)
        }
      ]
    };

    return (
      <Box mt={5}>
        <Paper>
          <Line data={chartData} />
        </Paper>
      </Box>
    );
  }

  return null;
};

const mapStateToProps = ({ type: { lightMode } }) => ({ lightMode });

export default connect(mapStateToProps)(ProjectMonitoringCard);
