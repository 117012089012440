import { USER_LIGHT_MODE } from 'actions/types';

/** Initial state */
const initState = {
  lightMode: null
};

const typeReducer = (state = initState, action) => {
  switch (action.type) {
    case USER_LIGHT_MODE:
      return {
        ...state,
        lightMode: action.data.lightMode
      };

    default:
      return state;
  }
};

export default typeReducer;
