import gql from 'graphql-tag';

export const GET_USER_QUERY = gql`
  {
    user {
      firstname
      lastname
      email
      role
      verified
      projectCount
      lightMode
      agencies {
        id
        name
        projects {
          id
          title
          summary
          projectType
          websiteUrl
          screenshot {
            secure_url
          }
        }
      }
      projects {
        id
        title
        summary
        projectType
        websiteUrl
        screenshot {
          secure_url
        }
      }
    }
  }
`;
