import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { List, ListItemIcon, Tooltip } from '@mui/material';
import { StyledListItem, StyledListItemText, MenuTitle } from '../DashboardMenu/styles';

const AdminMenu = ({ open, navigation }) => (
  <>
    {navigation.map(({ route, icon, text, children }, index) => (
      <Fragment key={index}>
        {open && <MenuTitle>{text}</MenuTitle>}

        <List component="nav" disablePadding>
          {children.map(({ route, icon, text, type, children, label }, index) => (
            <Tooltip key={index} title={!open ? label : ''} placement="right">
              <StyledListItem button exact component={NavLink} to={route}>
                <ListItemIcon>{icon}</ListItemIcon>
                <StyledListItemText primary={text} />
              </StyledListItem>
            </Tooltip>
          ))}
        </List>
      </Fragment>
    ))}
  </>
);

const mapStateToProps = ({ menu: { open } }) => ({ open });

export default connect(mapStateToProps)(AdminMenu);
