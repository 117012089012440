import React, { useState } from 'react';
import MUIRichTextEditor from 'mui-rte';
import { uniqueId } from 'lodash';
import { stateToHTML } from 'draft-js-export-html';
import { Mutation } from '@apollo/client/react/components';
import { Form, Formik } from 'formik';
import { Button } from '@mui/material';
import { validationSchema } from './validation';
import { CommentFormWrapper } from './styles';
import { CREATE_TICKET_COMMENT_MUTATION } from './data';
import DropZone from 'components/Attachments/DropZone';

const AddCommentToTicket = ({ ticketId }) => {
  /** Comment textarea */
  const [initialComment, setInitialComment] = useState({
    key: uniqueId(),
    value: ''
  });

  /** Dropzone Attachments */
  const [attachments, setAttachments] = useState([]);
  const removeAllAttachments = () => {
    setAttachments([]);
  };

  return (
    <Mutation mutation={CREATE_TICKET_COMMENT_MUTATION}>
      {(addSupportTicketComment, { loading, error, data }) => (
        <Formik
          initialValues={{
            comment: ''
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            const { comment, files } = values;

            const submitted = await addSupportTicketComment({
              variables: { ticketId, comment, files },
              refetchQueries: ['getSupportTicket']
            });

            if (submitted) {
              /** Reset the comment textarea */
              setInitialComment({
                key: uniqueId(),
                value: ''
              });

              /** Reset the attachments */
              removeAllAttachments();
            }
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <CommentFormWrapper>
              <Form noValidate>
                <MUIRichTextEditor
                  label="Your message"
                  key={initialComment.key}
                  value={initialComment.value}
                  onChange={(state) => setFieldValue('comment', stateToHTML(state.getCurrentContent()))}
                  toolbar={true}
                  controls={[
                    'bold',
                    'italic',
                    'underline',
                    'strikethrough',
                    'undo',
                    'redo',
                    'link',
                    'numberList',
                    'bulletList',
                    'quote'
                  ]}
                />

                <DropZone setFieldValue={setFieldValue} attachments={attachments} setAttachments={setAttachments} />

                <div style={{ textAlign: 'right' }}>
                  <Button type="submit" variant="contained" color="primary">
                    Add Message
                  </Button>
                </div>
              </Form>

              {loading && <div>Loading...</div>}
              {error && <div>There's appears to be an error. Please try again later.</div>}
              {data && <div>Your comment has been submitted successfully.</div>}
            </CommentFormWrapper>
          )}
        </Formik>
      )}
    </Mutation>
  );
};

export default AddCommentToTicket;
