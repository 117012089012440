import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Typography } from '@mui/material';
import { Lock } from '@mui/icons-material';
import { StyledIconButton, StyledPopover } from './styles';
import LoginForm from 'components/Forms/LoginForm';

const LoggedOutMenu = ({ loggedIn, email }) => {
  const [anchorEl, setAnchorEl] = useState(false);

  const handleMenuOpen = ({ currentTarget }) => setAnchorEl(currentTarget);

  const handleMenuClose = () => setAnchorEl(false);

  return (
    <>
      <StyledIconButton
        aria-owns={anchorEl ? 'material-appbar' : undefined}
        aria-haspopup="true"
        onClick={handleMenuOpen}
        color="inherit"
      >
        <Avatar>
          <Lock />
        </Avatar>
      </StyledIconButton>

      <StyledPopover
        anchorEl={() => anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Typography component="h4" variant="h5">
          Sign In
        </Typography>
        <LoginForm />
      </StyledPopover>
    </>
  );
};

LoggedOutMenu.propTypes = {
  loggedIn: PropTypes.bool,
  email: PropTypes.string
};

export default LoggedOutMenu;
