import { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { useQuery } from '@apollo/client';
import { Card, CardContent, Divider, Grid, Paper, Typography } from '@mui/material';
import { PROJECT_QUERY } from './data';
import { BottomGap } from './styles';
import ProjectIntro from 'components/Intros/ProjectIntro';
import SupportFormCard from 'components/Cards/SupportFormCard';
import SslCard from 'components/Cards/SslCard';
import ProjectTasksCard from 'components/Cards/ProjectTasksCard';
import PageLoader from 'components/Loaders/PageLoader';
import PageUnavailable from 'components/Messages/PageUnavailable';

const Project = ({ route, setIntro }) => {
  const id = route.match.params.id;

  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY/MM/DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY/MM/DD'));
  const [showOpen, setShowOpen] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [months, setMonths] = useState([]);
  const [projectData, setProjectData] = useState({});
  const [loadingTable, setLoadingTable] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  const { loading: queryLoading, error, data } = useQuery(PROJECT_QUERY, {
    variables: { id, startDate, endDate, showOpen },
    onCompleted: () => setFirstLoad(false)
  });

  useEffect(() => {
    if (!queryLoading) {
      setProjectData(data); // Update table data when query data changes
      setLoadingTable(false); // Set loading state of table to false
    }
  }, [data, queryLoading]);

  if (firstLoad && queryLoading) return <PageLoader />;
  if (error) return <PageUnavailable message="Unable to load project. Please try again later." />;

  const { project } = projectData || {};

  return (
    <>
      {project && <ProjectIntro project={project} setIntro={setIntro} />}

      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              {project && (
                <ProjectTasksCard
                  id={id}
                  data={projectData}
                  error={error}
                  loading={loadingTable}
                  setLoadingTable={setLoadingTable}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  endDate={endDate}
                  months={months}
                  setMonths={setMonths}
                  selectedMonth={selectedMonth}
                  setShowOpen={setShowOpen}
                  setSelectedMonth={setSelectedMonth}
                />
              )}
            </Grid>

            <Grid item xs={12} md={4}>
              {project?.projectLead && (
                <Card variant="outlined" sx={{ mb: 4 }}>
                  <CardContent>
                    <Typography variant="h6" component="h6" gutterBottom>
                      Project Lead
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <strong>Name:</strong> {project.projectLead.firstname} {project.projectLead.lastname}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Email:</strong> {project.projectLead.email}
                    </Typography>
                  </CardContent>
                </Card>
              )}

              {project?.clientDetails && (
                <Card variant="outlined" sx={{ mb: 4 }}>
                  <CardContent>
                    <Typography variant="h6" component="h6" gutterBottom>
                      Contacts
                    </Typography>

                    {project.clientDetails.map(({ email, first_name, last_name, importance, phone }, index) => (
                      <Fragment key={index}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          {importance.charAt(0).toUpperCase() + importance.slice(1)}
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                          <strong>Name:</strong> {first_name} {last_name}
                        </Typography>
                        {email && (
                          <Typography variant="body2" gutterBottom>
                            <strong>Email:</strong> {email}
                          </Typography>
                        )}

                        {phone && (
                          <Typography variant="body2" gutterBottom>
                            <strong>Phone:</strong> {phone}
                          </Typography>
                        )}

                        {index + 1 < project.clientDetails.length && <Divider sx={{ my: 3 }} />}
                      </Fragment>
                    ))}
                  </CardContent>
                </Card>
              )}

              {/* {project.allowance && (
                <Card variant="outlined" sx={{ mb: 4 }}>
                  <CardContent>
                    <Typography variant="h6" component="h6" gutterBottom>
                      Allowance
                    </Typography>

                    {project.allowance.map(({ date, hourly_rate, hours_available }, index) => (
                      <Fragment key={index}>
                        <Typography variant="body2" gutterBottom>
                          {date}
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                          {hourly_rate}
                        </Typography>

                        <Typography variant="body2" gutterBottom>
                          {hours_available}
                        </Typography>

                        {index + 1 < project.allowance.length && <Divider sx={{ my: 3 }} />}
                      </Fragment>
                    ))}
                  </CardContent>
                </Card>
              )} */}

              {project && <SslCard ssl={project.ssl} support={project.supportClient} />}

              <BottomGap>
                <Paper>{project && <SupportFormCard align="left" single={project.projectCode} />}</Paper>
              </BottomGap>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Project;
