import React from 'react';
import { Button, Divider, Typography, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { LockOpen } from '@mui/icons-material';
import { StyledPaper, StyledPaperInner, StyledChip } from '../styles';
import UpdateUserDetailsForm from 'components/Forms/UpdateUserDetailsForm';

const UserDetailsTable = ({ data }) => {
  const {
    getUser: {
      firstname,
      lastname,
      email,
      emailVerified,
      metadata: { lastSignInTime, creationTime }
    }
  } = data || {};

  return (
    <StyledPaper>
      <StyledPaperInner>
        <Typography variant="h6" component="h2">
          User Details
        </Typography>
      </StyledPaperInner>
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell scope="row">Full Name</TableCell>
            <TableCell>
              {firstname} {lastname}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell scope="row">Email</TableCell>
            <TableCell>{email}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell scope="row">Verified</TableCell>
            <TableCell>
              <StyledChip
                label={emailVerified ? 'Verified' : 'Not Verified'}
                size="small"
                variant="outlined"
                verified={emailVerified.toString()}
              />
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell scope="row">Created</TableCell>
            <TableCell>{creationTime}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell scope="row">Last sign in</TableCell>
            <TableCell>{lastSignInTime}</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <StyledPaperInner>
        <UpdateUserDetailsForm user={data.getUser} />
        <br />
        <Button startIcon={<LockOpen />}>Reset &amp; Send Password</Button>
      </StyledPaperInner>
    </StyledPaper>
  );
};

export default UserDetailsTable;
