import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { List } from '@mui/material';
import { Add, WorkOutline, WorkOff } from '@mui/icons-material';
import { StyledMenuButton, StyledCreateButton, StyledListItem, StyledNav } from './styles';
import * as ROUTES from 'constants/routes';

const SupportSidebar = ({ match, openTickets, solvedTickets, closedTickets }) => {
  const { path } = match;

  const menu = [
    {
      route: ROUTES.DASHBOARD_SUPPORT,
      icon: <WorkOutline />,
      title: 'Open',
      value: openTickets ? openTickets : 0
    },
    {
      route: ROUTES.DASHBOARD_SUPPORT_SOLVED,
      icon: <WorkOff />,
      title: 'Resolved',
      value: closedTickets ? closedTickets : 0
    }
    // {
    //   route: ROUTES.DASHBOARD_SUPPORT_CLOSED,
    //   icon: <WorkOff />,
    //   title: 'Closed',
    //   value: closedTickets ? closedTickets : 0
    // }
  ];

  return (
    <StyledNav>
      <List>
        {menu.map(({ route, icon, title, value }, index) => (
          <StyledListItem key={index}>
            <StyledMenuButton
              startIcon={icon}
              component={Link}
              to={route}
              className={path === route ? 'activeBtn' : ''}
            >
              {title} ({value})
            </StyledMenuButton>
          </StyledListItem>
        ))}

        <StyledListItem style={{ float: 'right' }}>
          <StyledCreateButton
            component={Link}
            to={ROUTES.DASHBOARD_SUPPORT_CREATE}
            variant="contained"
            endIcon={<Add />}
          >
            Create Ticket
          </StyledCreateButton>
        </StyledListItem>
      </List>
    </StyledNav>
  );
};

const mapStateToProps = ({ support: { openTickets, solvedTickets, closedTickets } }) => ({
  openTickets,
  solvedTickets,
  closedTickets
});

export default connect(mapStateToProps)(withRouter(SupportSidebar));
