import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Mutation } from '@apollo/client/react/components';
import { Button } from '@mui/material';
import { WarningSnackBar } from './styles';
import { VERIFICATION_EMAIL_MUTATION } from './data';

const VerifyEmail = ({ verified, email, extraSidebar }) => {
  /** If the user is verified we do not need to show this message */
  if (verified) {
    return null;
  }

  return (
    <Mutation mutation={VERIFICATION_EMAIL_MUTATION}>
      {(requestVerificationEmail, { loading, error, data }) => {
        let message = 'You have not verified your email address';
        let buttonText = 'Send verification email';

        if (loading) {
          message = 'Sending...';
        }

        if (data) {
          message = `An email has been sent to ${email}. If you have not received it, check your junk.`;
          buttonText = 'Re-send email';
        }

        return (
          <WarningSnackBar
            extraSidebar={extraSidebar}
            message={message}
            action={
              <Button
                color="secondary"
                size="small"
                variant="outlined"
                disableElevation={true}
                onClick={requestVerificationEmail}
              >
                {buttonText}
              </Button>
            }
          />
        );
      }}
    </Mutation>
  );
};

/** Type checking */
VerifyEmail.propTypes = {
  verified: PropTypes.bool,
  email: PropTypes.string,
  extraSidebar: PropTypes.bool
};

const mapStateToProps = ({ auth: { verified, email } }) => ({ verified, email });

export default connect(mapStateToProps)(VerifyEmail);
