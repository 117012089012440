import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Root, Main, MainInner } from './styles';
import { menuToggle } from 'actions/menu';
import Header from 'components/Header';
import DashboardMenu from 'components/Menus/DashboardMenu';
import DashboardFooter from 'components/Footer/DashboardFooter';
import VerifyEmail from 'views/Auth/VerifyEmail';

const DashboardTemplate = ({ component: Component, route, open, menuToggle, extraSidebar, intro, setIntro }) => {
  const handleDrawerOpen = () => menuToggle(true);
  const handleDrawerClose = () => menuToggle(false);

  return (
    <Root>
      <DashboardMenu handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen} />
      <Main extraSidebar={extraSidebar} open={open}>
        <Header route={route} intro={intro} />

        <VerifyEmail extraSidebar={extraSidebar} />

        <MainInner extraSidebar={extraSidebar}>
          <Component route={route} extraSidebar={extraSidebar} setIntro={setIntro} />
        </MainInner>

        <DashboardFooter />
      </Main>
    </Root>
  );
};

/** Type checking */
DashboardTemplate.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  route: PropTypes.object.isRequired,
  menuToggle: PropTypes.func,
  extraSidebar: PropTypes.bool
};

export default connect(
  ({ menu: { open } }) => ({
    open
  }),
  { menuToggle }
)(DashboardTemplate);
