import React from 'react';
import styled, { css } from 'styled-components';
import { AppBar } from '@mui/material';

const open = ({ open }) => {
  if (!open) {
    return css`
      left: 100px !important;
      width: calc(100% - 110px) !important;
    `;
  }
};

export const StyledAppBar = styled(({ open, lightMode, ...other }) => <AppBar {...other} />)`
  backdrop-filter: blur(6px);
  background-color: ${(p) => (p.lightMode ? 'rgba(255,255,255,0.8)' : 'rgba(30, 30, 30, 0.8)')};

  border-radius: ${(p) => p.theme.spacing(1)};
  transition: ${(p) =>
    p.theme.transitions.create(['width', 'left'], {
      easing: p.theme.transitions.easing.sharp,
      duration: p.theme.transitions.duration.leavingScreen
    })} !important;
  overflow: visible;
  top: 10px;
  left: 250px;
  right: 10px;
  width: calc(100% - 260px) !important;
  z-index: ${(p) => p.theme.zIndex.drawer + 1} !important;

  ${(p) => open(p)}
`;

export const AppBarSpacer = styled.div`
  min-height: 76px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    min-height: 84px;
  }
`;

export const Grow = styled.div`
  flex-grow: 1;
`;
