import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { PositionedIcon, StyledCard, StyledCardHeaderIcon, StyledDialog, StyledFab } from './styles';
import { Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import AddProjectForm from 'components/Forms/AddProjectForm';

const AddProjectCard = ({ lightMode, card }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {card ? (
        <StyledCard lightMode={lightMode} onClick={handleOpen}>
          <StyledCardHeaderIcon>
            <PositionedIcon />
            <Typography>Add project</Typography>
          </StyledCardHeaderIcon>
        </StyledCard>
      ) : (
        <StyledFab color="primary" aria-label="add" onClick={handleOpen}>
          <Add />
        </StyledFab>
      )}

      <StyledDialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth maxWidth="sm">
        <AddProjectForm />
      </StyledDialog>
    </>
  );
};

/** Type checking */
AddProjectCard.propTypes = {
  lightMode: PropTypes.bool,
  card: PropTypes.bool
};

const mapStateToProps = ({ type: { lightMode } }) => ({ lightMode });

export default connect(mapStateToProps)(AddProjectCard);
