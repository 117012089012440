import gql from 'graphql-tag';

export const VERIFICATION_EMAIL_MUTATION = gql`
  mutation {
    requestVerificationEmail {
      accepted {
        status
      }
    }
  }
`;
