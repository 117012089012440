import gql from 'graphql-tag';

export const LIST_ALL_AGENCIES_QUERY = gql`
  {
    agencies {
      id
      name
    }
  }
`;
