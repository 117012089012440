import gql from 'graphql-tag';

export const SITE_FOOTER_QUERY = gql`
  {
    content {
      site {
        footer
      }
    }
  }
`;
