import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from 'helpers/History';
import { PrivateRoute } from 'components/Routes/PrivateRoute';
import routes from 'routes';
import fetchUser from 'actions/auth/fetchUser';
import createGlobalTheme from 'assets/styles/globalTheme';
import GlobalStyles from 'assets/styles/globalStyles';

const App = ({ loggedIn, role, lightMode, fetchUser }) => {
  const [intro, setIntro] = useState(null);

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [lightState, setLightState] = useState(prefersDarkMode);

  const customTheme = createGlobalTheme(lightState);
  const theme = createTheme({ ...customTheme });

  useEffect(() => {
    fetchUser();

    /**
     * If the user has a setting for
     * lightmode we can update the state
     */
    if (lightMode !== null) {
      setLightState(lightMode);
    }
  }, [setLightState, fetchUser, lightMode]);

  return (
    <ThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />

        <Router history={history}>
          <Switch>
            {routes.map(
              (
                {
                  component,
                  path,
                  template: Template,
                  restricted,
                  title,
                  description,
                  routeRole,
                  extraSidebar,
                  disabled
                },
                key
              ) => {
                return restricted ? (
                  <PrivateRoute
                    exact
                    path={path}
                    key={key}
                    loggedIn={loggedIn}
                    userRole={role}
                    routeRole={routeRole}
                    render={(route) => (
                      <Template
                        component={component}
                        route={route}
                        title={title}
                        description={description}
                        extraSidebar={extraSidebar}
                        intro={intro}
                        setIntro={setIntro}
                      />
                    )}
                  />
                ) : (
                  <Route
                    exact
                    path={path}
                    key={key}
                    render={(route) => (
                      <Template
                        component={component}
                        route={route}
                        title={title}
                        description={description}
                        extraSidebar={extraSidebar}
                        intro={intro}
                        setIntro={setIntro}
                        disabled={disabled}
                      />
                    )}
                  />
                );
              }
            )}
          </Switch>
        </Router>
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

/** Type checking */
App.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  role: PropTypes.string,
  fetchUser: PropTypes.func.isRequired,
  lightMode: PropTypes.bool
};

const mapStateToProps = ({ auth: { loggedIn, role }, type: { lightMode } }) => ({ loggedIn, role, lightMode });

export default connect(mapStateToProps, { fetchUser })(App);
